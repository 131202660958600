import React from "react";
import DEMO from "../../../Constants";
import Aux from "../../hoc/_Aux";
import Logo from "../../../assets/images/new_vigipay_white.svg";

const navLogo = (props) => {
  let toggleClass = ["mobile-menu"];
  if (props.collapseMenu) {
    toggleClass = [...toggleClass, "on"];
  }

  return (
    <Aux>
      <div className="navbar-brand header-logo">
        <a href={DEMO.BLANK_LINK} className="b-brand">
          <div>
            <img
              src={Logo}
              alt="logo"
              style={{ width: "180px", height: "70px" }}
            />
          </div>
          {/* <span className="b-title">VPay</span> */}
        </a>
        <a
          href={DEMO.BLANK_LINK}
          className={toggleClass.join(" ")}
          id="mobile-collapse"
          onClick={props.onToggleNavigation}
        >
          <span />
        </a>
      </div>
    </Aux>
  );
};

export default navLogo;
