import actionTypes from '../actions/actionTypes';

const initialState = {
  headerColor: "",
  routePath:"/home/dashboard",
  selectedTopIndex:0,
  sideRoute:"/home/dashboard",
  showTable:true
};
export const appSettings = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APP_LOAD:
      {
        if (action.error) {
          return {
            ...state,
            isLoading: false,
            error: true
          };
        }

        return {
          ...state,
          token: action.token,
          appLoaded: true,
          currentUser: action.payload ? action.payload[1].user : {}, // action.payload ? action.payload.user : null
          isLoading: false
        };
      }
    case actionTypes.BEGIN_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.UPDATE_NAV_HEADER_COLOR:
      return {
        ...state,
        headerColor: action.payload.newColor,
        headerText: action.payload.newHeaderText,
        subMenus: action.payload.newsubMenu,
        menuSideColor:action.payload.newmenuSideColor,
        sideRoute:action.payload.newSideRoute
      };
    case actionTypes.CHANGE_TOP_NAV_MENU_ITEM:
      return{
        ...state,
        selectedTopIndex:action.payload.newIndex,
        previousRoute:action.payload.newRoute
      }
    case actionTypes.TOGGLE_CHART_AND_DATA_UI:
      return{
        ...state,
        showTable:action.payload.shouldShowTable
      }
    case actionTypes.GET_APP_CONFIG:
      return state;
    default:
      return state;
  }
};
