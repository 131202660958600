import React from "react";
import $ from "jquery";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const Dashboard = React.lazy(() => import("./Pages/Dashboard/Dashboard"));
const Integration = React.lazy(() => import("./Pages/Integration/Integration"));

const Transaction = React.lazy(() => import("./Pages/Transaction/Transaction"));
const Welcome = React.lazy(() => import("./Pages/Welcome/Welcome"));
const Profile = React.lazy(() => import("./Pages/Profile/Profile"));
const Users = React.lazy(() => import("./Pages/Users/Users"));
const ClientInfo = React.lazy(() => import("./Pages/ClientInfo/ClientInfo"));
const BankDestination = React.lazy(() =>
  import("./Pages/BankDestination/BankDestination")
);
const Configuration = React.lazy(() =>
  import("./Pages/Configuration/Configuration")
);
const GatewayAccount = React.lazy(() =>
  import("./Pages/DGatewayAccount/GatewayAccount/GatewayAccount")
);

const Settings = React.lazy(() => import("./Pages//Settings/Settings"));
const ClientService = React.lazy(() =>
  import("./Pages/ClientService/ClientService")
);
const SettlementsIntro = React.lazy(() =>
  import("./Pages/SettlementProfile/SettlementsIntro")
);
const SettlementsProfile = React.lazy(() =>
  import("./Pages/SettlementProfile/SettlementProfile")
);
const Collection = React.lazy(() => import("./Pages/Collection/Collection"));

const routes = [
  {
    path: "/home/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/home/integration",
    exact: true,
    name: "Integration",
    component: Integration,
  },
  {
    path: "/home/transaction",
    exact: true,
    name: "Transaction",
    component: Transaction,
  },
  {
    path: "/home/profile",
    exact: true,
    name: "Profile",
    component: Profile,
  },
  {
    path: "/home/client-service",
    exact: true,
    name: "ClientService",
    component: ClientService,
  },
  {
    path: "/home/users",
    exact: true,
    name: "Users",
    component: Users,
  },
  {
    path: "/home/welcome",
    exact: true,
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/home/client-info",
    exact: true,
    name: "ClientInfo",
    component: ClientInfo,
  },
  {
    path: "/home/bank-destination",
    exact: true,
    name: "BankDestination",
    component: BankDestination,
  },
  {
    path: "/home/configuration",
    exact: true,
    name: "Configuration",
    component: Configuration,
  },
  {
    path: "/home/gateway-account",
    exact: true,
    name: "GatewayAccount",
    component: GatewayAccount,
  },
  {
    path: "/home/settings",
    exact: true,
    name: "Settings",
    component: Settings,
  },
  {
    path: "/home/settlement",
    exact: true,
    name: "SettlementsIntro",
    component: SettlementsIntro,
  },
  {
    path: "/home/settlement-profile",
    exact: true,
    name: "SettlementsProfile",
    component: SettlementsProfile,
  },
  {
    path: "/home/collections",
    exact: true,
    name: "Collection",
    component: Collection,
  },
  // {
  //     path: "/home/gatewayaccount-currencypair",
  //     exact: true,
  //     name: "GatewayAccountCurrencyPair",
  //     component: GatewayAccountCurrencyPair,
  // },

  // Route for tabs
  {
    path: "/home/configuration/info",
    exact: true,
    name: "Configuration",
    component: Configuration,
  },
  {
    path: "/home/configuration/gateway-account",
    exact: true,
    name: "Configuration",
    component: Configuration,
  },
  {
    path: "/home/configuration/bank-destination",
    exact: true,
    name: "Configuration",
    component: Configuration,
  },
];

export default routes;
