import actionTypes from './actionTypes';

/* Utility functions */

const getPayloadFromData = res => {
  let result = {};
  const data = res.data;
  if (data) { /* Listed out possible scenarios of how the response data might be wrapped */
    const payload = data.data || data.result;
    result = payload || data;
  }
  return result;
};

const getDataFromResult = res => {
  if (Array.isArray(res)) {
    const result = [];
    res.map(obj =>
      result.push(getPayloadFromData(obj))
    );
    return result;
  }
  if (res) {
    return getPayloadFromData(res);
  }
};
/* Utility functions */

export function requestData(subtype) {
  return {
    type: actionTypes.BEGIN_REQUEST,
    subtype
  };
}

export function requestSuccess(data) {
  return {
    type: actionTypes.REQUEST_SUCCESS,
    data
  };
}
export function requestFailure(error) {
  return {
    type: actionTypes.REQUEST_FAILURE,
    error
  };
}

/** Formerly known as fetchData */
export function promiseAction(apiRequest, action) {
  return dispatch => {
    dispatch({type: actionTypes.BEGIN_REQUEST});
    apiRequest.then(res => {
      console.log('Result data', res);
      action.payload = getDataFromResult(res);
      requestSuccess(action.payload);
      dispatch(action);
    }).catch(err => {
      action.error = true; // We might want to submit the error messages
      action.payload = err.response || {};
      requestFailure(err);
      dispatch(action);
    });
  };
}

