export default {
  items: [
    {
      id: "navigation",
      title: "Navigation",
      type: "group",
      icon: "icon-navigation",
      children: [
        {
          id: "welcome",
          title: "Welcome",
          type: "item",
          url: "/home/welcome",
          classes: "nav-item",
          icon: "feather icon-home",
        },

      ],
    },
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/home/dashboard",
      icon: "fa fa-tachometer",
      code :"CanViewClient"
    },
    {
      id: "clients",
      title: "Client Info",
      type: "item",
      url: "/home/client-info",
      icon: "fa fa-users",
      code :"CanViewClient"
    },
    // {
    //   id: "transaction",
    //   title: "Transaction",
    //   type: "item",
    //   url: "/home/transaction",
    //   classes: "nav-item",
    //   icon: "fa fa-exchange",
    //   code:"CanViewTransaction"
    // },


    {
      id: "ui-transactionmgt",
      title: "UI ELEMENT",
      type: "group",
      icon: "icon-ui",
      permission: ["CanViewTransaction"],
      code: "CanViewTransaction",
      children: [
        {
          id: "transaction-basic",
          title: "Transaction",
          type: "collapse",
          icon: "fa fa-exchange",
          children: [
            {
              id: "user",
              title: "Disbursement",
              type: "item",
              url: "/home/transaction",
              icon: "fa fa-exchange",
              permission: ["CanViewTransaction"],
              code: "CanViewTransaction",
            },
            {
              id: "collection",
              title: "Collections",
              type: "item",
              url: "/home/collections",
              classes: "nav-item",
              icon: "fa fa-snowflake-o",
              code:"CanViewCollection"
            },
          ],
        },
      ],
    },
    {
      id: "clientservice",
      title: "Client Service",
      type: "item",
      url: "/home/client-service",
      classes: "nav-item",
      code:"CanViewClientService",
      icon: "fa fa-sellsy",
    },
    {
      id: "configuration",
      title: "Configuration Profile",
      type: "item",
      url: "/home/configuration",
      classes: "nav-item",
      icon: "fa fa-sun-o",
      code:"CanViewConfiguration"
    },
    {
      id: "settlement",
      title: "Settlement",
      type: "item",
      url: "/home/settlement",
      classes: "nav-item",
      icon: "fa fa-sitemap",
      code:"CanViewSettlementProfile"
    },
    // {
    //   id: "collection",
    //   title: "Collections",
    //   type: "item",
    //   url: "/home/collections",
    //   classes: "nav-item",
    //   icon: "fa fa-snowflake-o",
    //   code:"CanViewCollection"
    // },
    // {
    //   id: "gatewayaccount",
    //   title: "Gateway Account",
    //   type: "item",
    //   url: "/home/gateway-account",
    //   classes: "nav-item",
    //   icon: "fa fa-archive",
    //   code:"CanViewGatewayAccount"
    // },
    // {
    //   id: "gatewayaccountcurrencypair",
    //   title: "GatewayAccount Currency Pair",
    //   type: "item",
    //   url: "/home/gatewayaccount-currencypair",
    //   classes: "nav-item",
    //   icon: "fa fa-gg",
    //   code:"CanViewGatewayAccountCurrencyPair"
    // },
    {
      id: "integration",
      title: "Integration",
      type: "item",
      url: "/home/integration",
      classes: "nav-item",
      icon: "fa fa-microchip",
    },
    {
      id: "user",
      title: "Users",
      type: "item",
      url: "/home/users",
      permission: ["CanViewUser"],
      code: "CanViewUser",
      icon:"fa fa-user"
    },
    // {
    //   id: "settings",
    //   title: "Settings",
    //   type: "item",
    //   url: "/home/settings",
    //   code: "CanViewClient",
    //   icon:"fa fa-sun-o"
    // },
    // {
    //   id: "ui-usermanagement",
    //   title: "UI ELEMENT",
    //   type: "group",
    //   icon: "icon-ui",
    //   permission: ["CanViewUser"],
    //   code: "CanViewUser",
    //   children: [
    //     {
    //       id: "user-basic",
    //       title: "User Management",
    //       type: "collapse",
    //       icon: "fa fa-user",
    //       children: [
    //         {
    //           id: "user",
    //           title: "Users",
    //           type: "item",
    //           url: "/home/users",
    //           permission: ["CanViewUser"],
    //           code: "CanViewUser"
    //         }
    //       ]
    //     }
    //   ]
    // }
  ],
};
