export default {
    defaultPath: '/dashboard/default',
    basename: '/servicemgr', 
    layout: 'vertical', 
    preLayout: null, 
    collapseMenu: false, 
    layoutType: 'menu-light', 
    navIconColor: false,
    headerBackColor: 'header-default', 
    navBackColor: 'navbar-default',
    navBrandColor: 'brand-default', 
    navBackImage: false,
    rtlLayout: false, 
    navFixedLayout: true,
    headerFixedLayout: false, 
    boxLayout: false,
    navDropdownIcon: 'style1', 
    navListIcon: 'style1',
    navActiveListColor: 'active-default', 
    navListTitleColor: 'title-default',
    navListTitleHide: false,
    configBlock: false,
    layout6Background : 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', 
    layout6BackSize : '',
};
