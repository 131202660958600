import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

import ChatList from "./ChatList";
import Aux from "../../hoc/_Aux";
import DEMO from "../../../Constants";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updatingClient } from "logic/actions/actionTypes";
import { CustomAvatar } from "components/Avatar/CustomAvatar";
const NavRight = (props) => {
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.authReducer);
  const authData = authSelector && authSelector;

  console.log({authData, authSelector})
  const [listOpen, setlistOpen] = useState(false);

  const logout = () => {
    console.log("logout props", props);
    dispatch(updatingClient(false));
    localStorage.removeItem("persist:root");
    localStorage.clear();
    props.history.push("/login");
  };

  const roleName =
    authData &&
    authData.userdata.user.roles &&
    authData.userdata.user.roles[0].name;
  return (
    <Aux>
      <div></div>
      <ul className="navbar-nav ml-auto d-flex">
        {/* <CustomToolTip
          tooltiptext="Your business is in Test Mode. Submit all compliance forms to activate live payments."
          show
          content={
            <CustomButton
              text="Test Mode"
              className="btn-test-mode"
              shadow={false}
              show={true}
            />
          } /> */}
        {/* <li>
                        <Dropdown alignRight={!this.props.rtlLayout}>
                            <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                                <i className="icon feather icon-bell"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu alignRight className="notification">
                                <div className="noti-head">
                                    <h6 className="d-inline-block m-b-0">Notifications</h6>
                                    <div className="float-right">
                                        <a href={DEMO.BLANK_LINK} className="m-r-10">mark as read</a>
                                        <a href={DEMO.BLANK_LINK}>clear all</a>
                                    </div>
                                </div>
                                <ul className="noti-body">
                                    <li className="n-title">
                                        <p className="m-b-0">NEW</p>
                                    </li>
                                    <li className="notification">
                                        <div className="media">
                                            <img className="img-radius" src={Avatar1} alt="Generic placeholder"/>
                                            <div className="media-body">
                                                <p><strong>John Doe</strong><span className="n-time text-muted"><i
                                                    className="icon feather icon-clock m-r-10"/>30 min</span></p>
                                                <p>New ticket Added</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="n-title">
                                        <p className="m-b-0">EARLIER</p>
                                    </li>
                                    <li className="notification">
                                        <div className="media">
                                            <img className="img-radius" src={Avatar2} alt="Generic placeholder"/>
                                            <div className="media-body">
                                                <p><strong>Joseph William</strong><span className="n-time text-muted"><i
                                                    className="icon feather icon-clock m-r-10"/>30 min</span></p>
                                                <p>Prchace New Theme and make payment</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="notification">
                                        <div className="media">
                                            <img className="img-radius" src={Avatar3} alt="Generic placeholder"/>
                                            <div className="media-body">
                                                <p><strong>Sara Soudein</strong><span className="n-time text-muted"><i
                                                    className="icon feather icon-clock m-r-10"/>30 min</span></p>
                                                <p>currently login</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className="noti-footer">
                                    <a href={DEMO.BLANK_LINK}>show all</a>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li> */}
        {/* <li className={this.props.rtlLayout ? 'm-r-15' : 'm-l-15'}>
                        <a href={DEMO.BLANK_LINK} className="displayChatbox" onClick={() => {this.setState({listOpen: true});}}><i className="icon feather icon-mail"/></a>
                    </li> */}
        <li style={{ display: "flex" }}>
          <div className="d-inline-flex">
            <div>
              <div style={{ position: "inherit", marginTop: "14px" }}>
                <label>
                  {authData &&
                    authData.userdata &&
                    authData.userdata.user.firstName}{" "}
                  {authData &&
                    authData.userdata &&
                    authData.userdata.user.lastName}
                </label>
              </div>
              <div>
                <label style={{ fontSize: "10px", margin: "auto" }}>
                  {roleName}
                </label>
              </div>
            </div>
            <Dropdown
              alignRight={!props.rtlLayout}
              style={{ marginLeft: "30px" }}
              className="drp-user"
            >
              <Dropdown.Toggle variant={"link"} id="dropdown-basic">
                {/* <i className="icon feather icon-settings"/> */}

                <CustomAvatar
                  fullname={authData.userdata.user.firstName.concat(
                    ` ${authData.userdata.user.lastName}`
                  )}
                  round
                  size="40"
                  color="#2992D0"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight className="profile-notification">
                <div
                  className="pro-head"
                  style={{ backgroundColor: "#2992D0" }}
                >
                  <span>
                    {authData &&
                      authData.userdata &&
                      authData.userdata.user.firstName}{" "}
                    {authData &&
                      authData.userdata &&
                      authData.userdata.user.lastName}
                  </span>
                  <a
                    href={DEMO.BLANK_LINK}
                    className="dud-logout"
                    onClick={logout}
                    title="Logout"
                  >
                    <i className="feather icon-log-out" />
                  </a>
                </div>
                <ul className="pro-body">
                  {/* <li><a href={DEMO.BLANK_LINK} className="dropdown-item"><i className="feather icon-settings"/> Settings</a></li> */}
                  <li>
                    <Link to="/home/profile" className="dropdown-item">
                      <i className="feather icon-user" /> Profile
                    </Link>
                  </li>
                  {/* <li><a href={DEMO.BLANK_LINK} className="dropdown-item"><i className="feather icon-mail"/> My Messages</a></li> */}
                  <li onClick={logout}>
                    <a href={DEMO.BLANK_LINK} className="dropdown-item">
                      <i className="feather icon-lock" /> Log Out
                    </a>
                  </li>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </li>
      </ul>
      <ChatList
        listOpen={listOpen}
        closed={() => {
          setlistOpen(false);
        }}
      />
    </Aux>
  );
};

export default NavRight;
