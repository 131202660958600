import actionTypes from "../actions/actionTypes";

const initialState = {
  allpermissions: [],
  currentpermissions: [],
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        allpermissions: action.payload.allpermissions,
      };

    case actionTypes.SAVE_CURRENT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        currentpermissions: action.payload.currentpermissions,
      };

    default:
      return state;
  }
};

export default permissionsReducer;
