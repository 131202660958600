import actionTypes from "../actions/actionTypes";

const initialState = {
  allroles: [],
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ROLES_SUCCESS:
      return {
        ...state,
        allroles: action.payload.allroles,
      };

    default:
      return state;
  }
};

export default rolesReducer;
