import { toast } from "react-toastify";
import { RoleEnum } from "./Constants";
import SimpleCryptp from "simple-crypto-js";
import TableExport from "tableexport";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import React from "react";
import CustomToolTip from "components/Input/CustomToolTip";
import { Chip } from "@material-ui/core";

export const presets = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(54, 162, 235)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
};

export const appHelpers = {
  toCapitalLetters: (value) => {
    if (typeof value === "string") {
      return value.toLocaleUpperCase();
    }
  },
  trimEvery: (t) => {
    return t.replace(/\s/g, "");
  },
  readFile: (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  },
  // from stackoverflow
  dataURLtoFile: (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  },
  formatLargeNumbers: (x) => {
    if (isNaN(x)) return x;

    if (x < 9999) {
      return x;
    }

    if (x < 1000000) {
      return Math.round(x / 1000) + "K";
    }
    if (x < 10000000) {
      return (x / 1000000).toFixed(2) + "M";
    }

    if (x < 1000000000) {
      return Math.round(x / 1000000) + "M";
    }

    if (x < 1000000000000) {
      return Math.round(x / 1000000000) + "B";
    }

    return "1T+";
  },
  genClientRef: (length = 16) => {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  madParse(x) {
    //   used to parse '1500.00' from string to float
    return x === x * 1 ? x * 1 : x;
  },
  numberWithCommas: (x, currency) => {
    if (x === 0) return currency + "0";
    if (!x) return "";
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return currency + parts.join(".");
  },
  getYears: () => {
    var currentYear = new Date().getFullYear();
    let max = currentYear - 10;
    let years = [];
    for (var year = currentYear; year >= max; year--) {
      years.push(year.toString());
    }
    return years;
  },
  formatData: (data) => {
    return data.map((item) => ({
      FirstName: item.firstName,
      Email: item.email,
      LastName: item.lastName,
      Status: item.status,
      RoleName: RoleEnum.filter((role) => item.roleId === role.value)[0].label,
    }));
  },
  alertError: (message, duration) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration,
    });
  },
  alertSuccess: (message, duration) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration,
    });
  },
  filterByValue: (array, index) => {
    return array.filter((o) => Object.keys(o).some((k) => o[k] === index));
  },
  createRandomString: () => {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  },

  truncateEmail: (email) => {
    if (email) {
      if (email.length > 20) {
        return email.substring(0, 20) + "...";
      }
      return email;
    } else {
      return "nil.nil@gmail.com";
    }
  },

  data2pdf: (rowData, headers, documentTitle) => {
    // Construct the dataset for Pdf
    let data = rowData;
    // Construct the headers for the Pdf
    // header format :
    // [
    //   {
    //     alias: "Email ",
    //     name: "Email",
    //     flex: 1,
    //   },
    //   {
    //     alias: "LastName ",
    //     name: "LastName",
    //     flex: 1,
    //   },
    //   {
    //     alias: "FirstName ",
    //     name: "FirstName",
    //     flex: 1,
    //   },
    //   {
    //     alias: "RoleName",
    //     name: "RoleName",
    //     flex: 1,
    //   },
    // ]
    // Generate the Pdf
    window.objectExporter({
      type: "pdf",
      exportable: data,
      headers: headers,
      fileName: "sample_pdf",
      documentTitle: documentTitle,
      headerStyle:
        "font-weight: bold; padding: 5px; border: 1px solid #dddddd;",
      cellStyle: "border: 1px solid lightgray; margin-bottom: -1px;",
    });
  },

  data2csv: (data, headers, mainHeader, formatData) => {
    // Construct the dataset for CSV

    const FILEEXTENSION = ".csv";
    const MIMETYPE = "text/csv";
    const headerString = headers.map((item) => `"${item}"`).join(",");
    let instance = new TableExport(document.createElement("table"), {});
    const dataArr = formatData.call(this, data).map((item, index) => {
      return [++index, ...Object.values(item)];
    });
    const dataArr2 = dataArr.map((item) =>
      item.map((item) => `"${item}"`).join(",")
    );
    instance.export2file(
      [mainHeader, headerString, ...dataArr2].join("\r\n"),
      MIMETYPE,
      mainHeader,
      FILEEXTENSION
    );
  },
  data2excel: (data, headers, mainHeader, formatData) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const dataArr = formatData.call(this, data).map((item, index) => {
      return {
        SN: ++index,
        ...item,
      };
    });
    const ws = XLSX.utils.json_to_sheet(dataArr);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data2 = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data2, mainHeader + fileExtension);
  },

  returnAllRolesNams(roles) {
    let names = [];
    for (let i in roles) {
      names.push(roles[i].name);
    }
    return names.toString();
  },
  renderMultipleRoles(r) {
    let domElems = [];

    // we show just first three and show the rest on hover
    if (r.length > 4) {
      let firstThreeAgain = r.slice(0, 3);
      for (let j in firstThreeAgain) {
        domElems.push(
          <Chip
            style={{ backgroundColor: "#edebfa" }}
            size="small"
            label={firstThreeAgain[j].name}
          />
        );
      }

      domElems.push(
        <CustomToolTip
          tooltiptext={appHelpers.returnAllRolesNams(r)}
          show
          content={
            <div style={{ fontWeight: "bold" }}>view +{r.length - 3}</div>
          }
        />
      );
    } else {
      let firstThree = r.slice(0, 3);
      for (let j in firstThree) {
        domElems.push(
          <Chip
            style={{ backgroundColor: "#edebfa" }}
            size="small"
            label={firstThree[j].name}
          />
        );
      }
    }
    return domElems;
  },

  formatdocs(data) {
    return data.map((item, index) => {
      return {
        FirstName: item.FirstName,
        LastName: item.LastName,
        EmailAddress: item.Email,
        RoleName: item.RoleName,
      };
    });
  },
  formatCountries(data) {
    return data.map((item, index) => {
      return {
        label: item.name,
        value: item.code2,
      };
    });
  },
  formAvailableCoutries(data) {
    return data.map((item, index) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  },
  formatTransactionCoutries(data) {
    return data.map((item, index) => {
      return {
        label: `${item.name} - ${item.isO3}`,
        value: item.isO3,
      };
    });
  },
  formatTransactionCurrency(data) {
    return data.map((item, index) => {
      return {
        label: `${item.name} - ${item.currencyCode}`,
        value: item.currencyCode,
      };
    });
  },

  formatTransactionBank(data) {
    return data.map((item, index) => {
      return {
        label: `${item.bankName} - ${item.bankCode}`,
        value: item.bankCode,
      };
    });
  },
  formatState(data) {
    return data.map((item, index) => {
      return {
        label: item.name,
        value: item.code,
      };
    });
  },
  formatResponseEnum(data) {
    return data.map((item, index) => {
      return {
        label: `${item.responseCode}`,
        value: item.id,
      };
    });
  },
  formatCredentialKeys(data) {
    if (data) {
      return data.map((item, index) => {
        return {
          gatewayCredentialKeyId: item.id,
          key: item.key,
          value: item.value || "",
        };
      });
    } else {
      return [];
    }
  },
  formatCustomEnum(data) {
    return data.map((item, index) => {
      return {
        label: `${item.configurationTag}`,
        value: item.id,
      };
    });
  },
  formatBDCustomEnum(data) {
    return data.map((item, index) => {
      return {
        label: `${item.configurationTag}`,
        value: item.id,
      };
    });
  },

  formatGatewayAccountEnum(data) {
    return data.map((item, index) => {
      return {
        label: `${item.accountName}-${item.gatewayAccountNumber}`,
        value: item.id,
      };
    });
  },
  formatTransactionClientServices(data) {
    return data.map((item, index) => {
      return {
        label: `${item.serviceName}`,
        value: item.serviceId,
      };
    });
  },
  formatTransactionCConfiguration(data) {
    return data.map((item, index) => {
      return {
        label: `${item.configurationTag}`,
        value: item.configurationTag,
      };
    });
  },

  formatTransactionCServices(data) {
    return data.map((item, index) => {
      return {
        label: `${item.serviceCode}`,
        value: item.serviceCode,
      };
    });
  },

  formatConfigurationClientServices(data) {
    return data.map((item, index) => {
      return {
        label: `${item.serviceName}`,
        value: item.serviceId,
      };
    });
  },

  formatEnum(data) {
    return data.map((item, index) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  },
  formatCustomDataEnum(data, keyName) {
    if (data) {
      return data.map((item, index) => {
        return {
          label: item[keyName],
          value: item?.id ?? ++index,
        };
      });
    }
  },
  formatExtraCustomDataEnum(data, keyName, valueName) {
    if (data) {
      return data.map((item, index) => {
        return {
          label: item[keyName],
          value: item[valueName],
        };
      });
    }
  },
  formatObjResponse(data) {
    let arr = [];
    if (data) {
      // return data.map((item, index) => {
      for (const [key, value] of Object.entries(data)) {
        arr.push({
          label: key,
          value: value,
        });
      }
    }
    return arr;
  },
  formatCurrencyPairEnum(data) {
    return data.map((item, index) => {
      return {
        label: `${item.receivingCurrencyCode}-${item.sendingCurrencyCode}`,
        value: item.id,
      };
    });
  },

  resetPagination(context, value) {
    context.setState((prevState) => ({
      [value]: {
        ...prevState[value],
        PageSize: 10,
      },
    }));
  },

  cryptographyService: () => {
    const simpleCrypto = new SimpleCryptp("T()o(){o){R}");
    return simpleCrypto;
  },
  isValidUrl: (string) => {
    // try {
    //   new URL(string);
    // } catch (_) {
    //   return false;
    // }

    // return true;
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(string);
  },

  doSearch: (context, value, input, searchName) => {
    if (value.length > 2) {
      context.setState((prevState) => ({
        [searchName]: {
          ...prevState[searchName],
          [input]: value,
        },
      }));
    }

    if (value.length === 0) {
      context.setState((prevState) => ({
        [searchName]: {
          ...prevState[searchName],
          [input]: value,
        },
      }));
    }
  },
  enterHandler: (id) => {
    window.addEventListener("keyup", function (event) {
      // 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        event.preventDefault();
        // Trigger the button element with a click
        let loginTrigger = document.querySelector(`#${id}`);
        if (loginTrigger && loginTrigger !== null) {
          loginTrigger.click();
        }
      }
    });
  },

  randomNum: (length) =>
    Math.floor(
      Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
    ),
};
