import React, { useState, useEffect, useRef } from "react";

import "../../../assets/scss/style.scss";
import Aux from "../../../components/hoc/_Aux";
import Breadcrumb from "../../../components/Breadcrumb";
import { oldsetupAccountErrors } from "validation";
import CustomInput from "components/Input/CustomInput";
import { CircularProgress } from "@material-ui/core";
// import { useDispatch } from "react-redux";
import { appHelpers } from "appHelpers";

const SetupAccount = props => {
  const hasError = useRef(null);
  const [data, setData] = useState({
    rpassword: "",
    rconfirmPassword: "",
  });
  const [err, setError] = useState(oldsetupAccountErrors);
  const [hidden, setHidden] = useState(true);
  const [hide, setHide] = useState(true);
  const [isSuccess, setisSuccess] = useState(false);
  const [loading, setloading] = useState(false);
  const [disableSetup, setdisableSetup] = useState(false);
  const [errorText, seterrorText] = useState("");
  const [isError, setisError] = useState(false);

  const toggleShow = () => {
    setHidden(!hidden);
  };
  useEffect(() => {
    if (!props.location) {
      props.history.push("/");
    }
    if (!props.location.state) {
      props.history.push("/");
    }
  });

  const secondToggleShow = () => {
    setHide(!hide);
  };

  const setup = () => {
    //
    setdisableSetup(true);
    setloading(true);
    seterrorText("");
    const { Constants } = props;

    props.validatorAll(
      [
        { name: "rpassword", value: data.rpassword, label: "Password" },
        { name: "rconfirmPassword", value: data.rconfirmPassword, label: "Confirm Password", rpassword: data.rpassword },
      ],
      "SetupAccount",
      setError,
      err,
      hasError
    );
    if (!hasError.current) {
      setloading(false);
      seterrorText("");
      setdisableSetup(false);
      return;
    }
    if (Object.keys(hasError.current).length > 0) {
      setloading(false);
      setdisableSetup(false);
      return;
    }

    const payload = {
      email: props.location.state.email,
      password: data.rpassword,
      confirmPassword: data.rconfirmPassword,
    };
    const SetupAccountService = props.Service(null, null);

    SetupAccountService.createItem(payload, Constants.ACCOUNT, Constants.SETUP_ACCOUNT)
      .then(res => {
        const { data } = res;
        if (data.requestSuccessful === true) {
          seterrorText("");
          setisError(false);
          setdisableSetup(false);
          setloading(false);
          setisSuccess(true);
          // const { responseData } = data;
          setTimeout(() => {
            props.history.push("/");
          }, 1500);
          return;
        } else {
          setisSuccess(false);
          setdisableSetup(false);
          setloading(false);
          setisError(true);
          seterrorText(data?.message);
          return;
        }
      })
      .catch(err => {
        if (err.response === undefined) {
          setdisableSetup(false);
          setloading(false);
          setisError(true);
          seterrorText("Check your network and please try again.");
        }
        if (err.response) {
          setdisableSetup(false);
          setloading(false);
          setisError(true);
          seterrorText(err?.response?.data?.message ?? "An error occurred, please try again");
        }
      });
  };

  const handleCreateFormInputChange = (input, label) => ({ target: { value } }) => {
    setData(prevState => ({
      ...prevState,
      [input]: value,
    }));
    props.validator({ name: input, value: value, label: label, rpassword: data.rpassword, rconfirmPassword: data.rconfirmPassword }, "SetupAccount", setError, err);
  };

  appHelpers.enterHandler("setupbutton");

  return (
    <Aux>
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="card">
            <div className="card-body text-center">
              <img src={"/vigipay_black.svg"} alt="Logo" style={{ width: "190px", height: "80px" }} />
              <h4 className="mb-5">
                <b>Setup Account</b>
              </h4>
              {/* <h6
                  className="text-muted mt-3"
                  style={{ marginBottom: "2rem" }}
                >
                  Enter a new passwrd
                </h6> */}

              <CustomInput value={props.location && props.location.state.email} id="eemail" labelText="Email" placeholder="test" type={"text"} disabled={true} />
              <div className="input-group mb-3" style={{ position: "relative" }}>
                <CustomInput
                  errorText={err.rpassword}
                  showError={err.rpassword.length > 0}
                  value={data.rpassword}
                  onChange={handleCreateFormInputChange("rpassword", "Password")}
                  id="reset-password"
                  labelText="Password"
                  placeholder="password"
                  type={hidden ? "password" : "text"}
                  endIcon={<>{hidden ? <i onClick={toggleShow} className="fa fa-eye" aria-hidden="true"></i> : <i onClick={toggleShow} className="fa fa-eye-slash" aria-hidden="true"></i>}</>}
                />
              </div>

              <CustomInput
                errorText={err.rconfirmPassword}
                showError={err.rconfirmPassword.length > 0}
                value={data.rconfirmPassword}
                onChange={handleCreateFormInputChange("rconfirmPassword", "Confirm Password")}
                id="login-password"
                labelText="Confirm Password"
                placeholder="enter a password again"
                type={hide ? "password" : "text"}
                endIcon={<>{hide ? <i onClick={secondToggleShow} className="fa fa-eye" aria-hidden="true"></i> : <i onClick={secondToggleShow} className="fa fa-eye-slash" aria-hidden="true"></i>}</>}
              />
              <p className="mb-2 text-muted"></p>
              {isError && (
                <h6 className="text-danger" style={{ marginBottom: "2rem" }}>
                  {errorText}
                </h6>
              )}
              {isSuccess && (
                <h6 className="text-success" style={{ marginBottom: "2rem" }}>
                  Password setup successful
                </h6>
              )}

              <button className="btn btn-primary shadow-2 mb-4 mainColor" style={{ width: "100%" }} disabled={disableSetup} id="setupbutton" onClick={setup}>
                {loading && <CircularProgress size={20} style={{ marginBottom: "-4px" }} color="white" />} Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </Aux>
  );
};

export default SetupAccount;
