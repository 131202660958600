import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import "../../../assets/scss/style.scss";
import Aux from "../../../components/hoc/_Aux";
import Breadcrumb from "../../../components/Breadcrumb";
import CustomInput from "components/Input/CustomInput";
import { forgotpasswordErrors } from "validation";
import { CircularProgress } from "@material-ui/core";
import { appHelpers } from "appHelpers";

const ForgotPassword = (props) => {
  const hasError = useRef(null);
  const [data, setData] = useState({
    Email: "",
  });
  const [err, setError] = useState(forgotpasswordErrors);
  const [showRecoverButton, setShowRecoverButton] = useState(true);
  const [showInputField, setShowInputField] = useState(true);
  const [isError, setisError] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [loading, setloading] = useState(false);
  const [errorText, seterrorText] = useState("");
  const [disableCreate, setdisableCreate] = useState(false);

  const recover = () => {
    // this.props.history.push("/home/dashboard")
    setisSuccess(false);
    setisError(false);
    setloading(true);
    seterrorText("");
    setdisableCreate(true);
    props.validatorAll(
      [{ name: "Email", value: data.Email, label: "Email" }],
      "ForgotPassword",
      setError,
      err,
      hasError
    );
    if (!hasError.current) {
      setloading(false);
      seterrorText("");
      setdisableCreate(false);
      return;
    }
    if (Object.keys(hasError.current).length > 0) {
      setloading(false);
      seterrorText("");
      setdisableCreate(false);
      return;
    }

    const payload = {
      Email: data.Email,
    };

    const ForgotPasswordService = props.Service(null, null);
    ForgotPasswordService.createItem(
      payload,
      props.Constants.ACCOUNT,
      props.Constants.FORGOT_PASSWORD
    )
      .then((res) => {
        const { data } = res;
        if (data.requestSuccessful === true) {
          setisSuccess(true);
          setisError(false);
          setloading(false);
          seterrorText("");
          setdisableCreate(false);
          setShowRecoverButton(false);
          setShowInputField(false);
        } else {
          setisSuccess(false);
          setisError(true);
          setloading(false);
          seterrorText(data.message);
          setdisableCreate(false);
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response === undefined) {
            setisSuccess(false);
            setisError(true);
            setloading(false);
            seterrorText("Check your network, or try again");
            setdisableCreate(false);
          } else {
            setisSuccess(false);
            setisError(true);
            setloading(false);
            seterrorText("Check your network, or try again");
            setdisableCreate(false);
          }
        }
      });
  };

  const handleCreateFormInputChange =
    (input, label) =>
    ({ target: { value } }) => {
      setData((prevState) => ({
        ...prevState,
        [input]: value,
      }));
      props.validator(
        { name: input, value: value, label: label },
        "ForgotPassword",
        setError,
        err
      );
    };

  appHelpers.enterHandler("forgotbutton");

  return (
    <Aux>
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="card">
            <div className="card-body text-center">
              <img
                src={"/vigipay_black.svg"}
                alt="Logo"
                style={{ width: "190px", height: "80px" }}
              />
              {showInputField ? (
                <h4 className="mb-5">
                  <b>Forgot Password</b>
                </h4>
              ) : null}
              {/* <h6 className="text-muted mt-3" style={{ marginBottom: "2rem" }}>Enter your email to reset your password</h6> */}
              {showInputField ? (
                <div className="input-group mb-3">
                  <CustomInput
                    errorText={err.Email}
                    showError={err.Email.length > 0}
                    value={data.Email}
                    onChange={handleCreateFormInputChange("Email", "Email")}
                    id="forgot-password"
                    labelText="Email address"
                    placeholder="Email address"
                  />
                </div>
              ) : null}
              <br />
              {isError && (
                <h6 className="text-danger" style={{ marginBottom: "2rem" }}>
                  {errorText}
                </h6>
              )}
              {isSuccess && (
                <h6 className="text-success" style={{ marginBottom: "2rem" }}>
                  A Reset link has been sent to your email
                </h6>
              )}

              {showRecoverButton ? (
                <button
                  id="forgotbutton"
                  className="btn btn-primary shadow-2 mb-4 mainColor"
                  disabled={disableCreate}
                  style={{ width: "100%" }}
                  onClick={recover}
                >
                  {loading && (
                    <CircularProgress
                      size={20}
                      style={{ marginBottom: "-4px" }}
                      color="white"
                    />
                  )}{" "}
                  Recover Password
                </button>
              ) : null}
              <p className="mb-2 text-muted" style={{ textAlign: "center" }}>
                {" "}
                <NavLink to="/login" style={{ color: "#2992D0" }}>
                  Login
                </NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Aux>
  );
};

export default ForgotPassword;
