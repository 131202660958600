import React, { Component } from "react";
import { connect } from "react-redux";

import NavLeft from "./NavLeft";
import NavRight from "./NavRight";
import Aux from "../hoc/_Aux";
import DEMO from "../../Constants";
import actionTypes from "../../logic/actions/actionTypes";
import Logo from "../../assets/images/new_vigipay_white.svg";

class NavBar extends Component {
  render() {
    let headerClass = ["navbar", "pcoded-header", "navbar-expand-lg", this.props.headerBackColor];
    if (this.props.headerFixedLayout) {
      headerClass = [...headerClass, "headerpos-fixed"];
    }

    let toggleClass = ["mobile-menu"];
    if (this.props.collapseMenu) {
      toggleClass = [...toggleClass, "on"];
    }

    return (
      <Aux>
        <header className={headerClass.join(" ")}>
          <div className="m-header">
            <a className={toggleClass.join(" ")} id="mobile-collapse1" href={DEMO.BLANK_LINK} onClick={this.props.onToggleNavigation}>
              <span />
            </a>
            <div className="b-bg">
              <img src={Logo} style={{ width: "180px", height: "70px" }} alt="brand-logo" />
            </div>
          </div>
          <a className="mobile-menu" id="mobile-header" href={DEMO.BLANK_LINK}>
            <i className="feather icon-more-horizontal" />
          </a>
          <div className="collapse navbar-collapse" style={{ background: "white" }}>
            <NavLeft history={this.props.history} />
            <NavRight rtlLayout={this.props.rtlLayout} history={this.props.history} />
          </div>
        </header>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    rtlLayout: state.navigation.rtlLayout,
    headerBackColor: state.navigation.headerBackColor,
    headerFixedLayout: state.navigation.headerFixedLayout,
    collapseMenu: state.navigation.collapseMenu,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleNavigation: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
