import React from "react";
import "./PreLoader.css";

const PreLoader = () => {
  return (
    <div className="preloader2" style={{ marginTop: `${window.innerHeight / 2.2}px` }}>
      <div className="loader">
        <div className="loader__figure"></div>
        <p className="loader__label">Service Manager Merchant</p>
      </div>
    </div>
  );
};

export default PreLoader;
