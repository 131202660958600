import React, { useState, useEffect, useRef } from "react";
import "../../../assets/scss/style.scss";
import Aux from "../../../components/hoc/_Aux";
import Breadcrumb from "../../../components/Breadcrumb";
import { resetPasswordErrors } from "validation";
import CustomInput from "components/Input/CustomInput";
import { appHelpers } from "appHelpers";
import { CircularProgress } from "@material-ui/core";

const ResetPassword = (props) => {
  const hasError = useRef(null);

  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [err, setError] = useState(resetPasswordErrors);
  const [hiddenOne, setHiddenOne] = useState(true);
  const [hiddenTwo, setHiddenTwo] = useState(true);
  const [resetting, setResetting] = useState(false);
  const [setDisableReset] = useState(false);
  const [token, settoken] = useState("");
  const [id, setid] = useState("");
  const [errorText, seterrorText] = useState("");
  const [isSuccess, setisSuccess] = useState("");
  const [isError, setisError] = useState("");
  const toggleShow = () => {
    setHiddenOne(!hiddenOne);
  };

  const secondToggleShow = () => {
    setHiddenTwo(!hiddenTwo);
  };

  useEffect(() => {
    const { location } = props;

    if (location) {
      const { pathname } = props.location;

      const token = pathname.split("/");
      if (token) {
        if (token[2] && token[3]) {
          // token very valid
          settoken(token[2]);
          setid(token[3]);
        } else {
          // token still invdalid
          appHelpers.alertWarning(
            "Token Invalid, Reset Your Password Again",
            4000
          );
          props.history.push("/forgot-password");
        }
      } else {
        // token invalid
        appHelpers.alertWarning(
          "Token Invalid, Reset Your Password Again",
          4000
        );
        props.history.push("/forgot-password");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reset = () => {
    //
    const { Constants } = props;
    setResetting(true);
    setDisableReset(true);
    seterrorText("");
    props.validatorAll(
      [
        { name: "password", value: data.password, label: "Password" },
        {
          name: "confirmPassword",
          value: data.confirmPassword,
          password: data.password,
          label: "Confirm Password",
        },
      ],
      "ResetPassword",
      setError,
      err,
      hasError
    );

    if (!hasError.current) {
      setResetting(false);
      setDisableReset(false);
      return;
    }
    if (Object.keys(hasError.current).length > 0) {
      setResetting(false);
      setDisableReset(false);
      return;
    }

    console.log("validated");
    const payload = {
      userId: id,
      token: token,
      newPassword: data.password,
    };

    const ResetPasswordService = props.Service(null, null);

    ResetPasswordService.createItem(
      payload,
      Constants.ACCOUNT,
      Constants.RESET_PASSWORD
    )
      .then((res) => {
        const { data } = res;
        if (data.responseData === true) {
          setDisableReset(false);
          setisSuccess(true);
          setisError(false);
          setResetting(false);
          seterrorText("Reset Password Successful");
          // this.setState({disableCreate:false, isSuccess: true, isError: false, loading: false, errorText: "Reset Password Successful" })
          setTimeout(() => {
            props.history.push("/");
          }, 1500);
          return;
        } else {
          // this.setState({disableCreate:false, isSuccess: false, isError: true, errorText: data.message, loading: false })
          setDisableReset(false);
          setisSuccess(false);
          setisError(true);
          setResetting(false);
          seterrorText(data.message);
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response === undefined) {
            // this.setState({disableCreate:false, loading: false, isSuccess: false, isError: true, errorText: "Check your network, please try again" })
            setDisableReset(false);
            setisSuccess(false);
            setisError(true);
            setResetting(false);
            seterrorText("Check your network, please try again");
          }
          if (err.response) {
            // this.setState({disableCreate:false, loading: false, isSuccess: false, isError: true, errorText: "There was an error, please try again" })
            setDisableReset(false);
            setisSuccess(false);
            setisError(true);
            setResetting(false);
            seterrorText("There was an error, please try again");
          }
        }
      });
    // this.props.history.push("/home/dashboard")
  };

  const handleCreateFormInputChange =
    (input, label) =>
    ({ target: { value } }) => {
      setData((prevState) => ({
        ...prevState,
        [input]: value,
      }));
      props.validator(
        { name: input, value: value, label: label, password: data.password },
        "ResetPassword",
        setError,
        err
      );
    };
  appHelpers.enterHandler("resetbutton");
  return (
    <Aux>
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="card">
            <div className="card-body text-center">
              <img
                src={"/vigipay_black.svg"}
                alt="Logo"
                style={{ width: "190px", height: "80px" }}
              />
              <h4 className="mb-5">
                <b>Reset Password</b>
              </h4>
              {/* <h6
                  className="text-muted mt-3"
                  style={{ marginBottom: "2rem" }}
                >
                  Enter a new passwrd
                </h6> */}
              <div
                className="input-group mb-3"
                style={{ position: "relative" }}
              >
                <CustomInput
                  errorText={err.password}
                  showError={err.password.length > 0}
                  value={data.password}
                  onChange={handleCreateFormInputChange("password", "Password")}
                  id="reset-password"
                  labelText="Password"
                  placeholder="password"
                  type={hiddenOne ? "password" : "text"}
                  endIcon={
                    <>
                      {hiddenOne ? (
                        <i
                          onClick={toggleShow}
                          className="fa fa-eye"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          onClick={toggleShow}
                          className="fa fa-eye-slash"
                          aria-hidden="true"
                        ></i>
                      )}
                    </>
                  }
                />
              </div>

              <CustomInput
                errorText={err.confirmPassword}
                showError={err.confirmPassword.length > 0}
                value={data.confirmPassword}
                onChange={handleCreateFormInputChange(
                  "confirmPassword",
                  "Confirm Password"
                )}
                id="login-password"
                labelText="Confirm Password"
                placeholder="enter a password again"
                type={hiddenTwo ? "password" : "text"}
                endIcon={
                  <>
                    {hiddenTwo ? (
                      <i
                        onClick={secondToggleShow}
                        className="fa fa-eye"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        onClick={secondToggleShow}
                        className="fa fa-eye-slash"
                        aria-hidden="true"
                      ></i>
                    )}
                  </>
                }
              />
              <p className="mb-2 text-muted"></p>
              {isError && (
                <h6 className="text-danger" style={{ marginBottom: "2rem" }}>
                  {errorText}
                </h6>
              )}
              {isSuccess && (
                <h6 className="text-success" style={{ marginBottom: "2rem" }}>
                  Password reset successfully
                </h6>
              )}

              <button
                className="btn btn-primary shadow-2 mb-4 mainColor"
                id="resetbutton"
                style={{ width: "100%" }}
                onClick={reset}
              >
                {resetting && (
                  <CircularProgress
                    size={20}
                    style={{ marginBottom: "-4px" }}
                    color="white"
                  />
                )}{" "}
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </Aux>
  );
};

export default ResetPassword;
