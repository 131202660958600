import actionTypes from "../actions/actionTypes";

const initialState = {
  userdata: {},
  expiresIn: 0,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_USER_SUCCESS:
      return {
        ...state,
        userdata: action.payload.userdata,
      };
    case actionTypes.SET_EXPIRATION:
      return {
        ...state,
        expiresIn: action.payload.expiresIn,
      };
    default:
      return state;
  }
};

export default authReducer;
