import actionTypes from "../actions/actionTypes";

const initialState = {
  kyclevels: [],
};

const kyclevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_KYC_LEVELS_SUCCESS:
      return {
        ...state,
        kyclevels: action.payload.kyclevels,
      };

    default:
      return state;
  }
};

export default kyclevelReducer;
