import actionTypes from "../actions/actionTypes";

const initialState = {
  servicegroups: [],
};

const servicegroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_SERVICE_GROUPS_SUCCESS:
      return {
        ...state,
        servicegroups: action.payload.servicegroups,
      };

    default:
      return state;
  }
};

export default servicegroupReducer;
