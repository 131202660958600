import { combineReducers, createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { appSettings } from "./reducers/appSettings";
import navigation from "./reducers/navigation";
import microservicesReducer from "./reducers/microservicesReducer";
import servicegroupReducer from "./reducers/servicegroupReducer";
import countriesReducer from "./reducers/countriesReducer";
import clientServicesReducer from "./reducers/clientServicesReducer";
import clientConfigurationReducer from "./reducers/clientConfigurationReducer";
import gatewayReducer from "./reducers/gatewayReducer";
import clientsReducer from "./reducers/clientsReducer";
import servicesReducer from "./reducers/servicesReducer";
import configurationReducer from "./reducers/configurationReducer";
import responseCodeReducer from "./reducers/responseCodeReducer";
import currencypairReducer from "./reducers/currencypairReducer";
import currencyReducer from "./reducers/currencyReducer";
import permissionsReducer from "./reducers/permissionsReducer";
import authReducer from "./reducers/authReducer";
import rolesReducer from "./reducers/rolesReducer";
import kyclevelReducer from "./reducers/kyclevelReducer";
import gatewaychargebearerReducer from "./reducers/gatewaychargebearerReducer";
import bankReducer from "./reducers/bankReducer";
import settlementProfileReducer from "./reducers/settlementProfileReducer";
import chargeTypeReducer from "./reducers/chargeTypeReducer";
import thunk from "redux-thunk";
import { promiseMiddleware } from "./middleware";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import localForage from "localforage";
const rootReducer = combineReducers({
  appSettings,
  navigation,
  microservicesReducer,
  servicegroupReducer,
  countriesReducer,
  clientServicesReducer,
  clientConfigurationReducer,
  configurationReducer,
  rolesReducer,
  gatewayReducer,
  clientsReducer,
  servicesReducer,
  responseCodeReducer,
  currencypairReducer,
  currencyReducer,
  permissionsReducer,
  authReducer,
  kyclevelReducer,
  gatewaychargebearerReducer,
  bankReducer,
  settlementProfileReducer,
  chargeTypeReducer,
});

const persistConfig = {
  key: "root",
  storage: localForage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  let store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk, promiseMiddleware, logger)));
  let persistor = persistStore(store);
  return { store, persistor };
};
