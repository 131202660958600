import actionTypes from "../actions/actionTypes";

const initialState = {
  allclients: [],
  myclients: [],
  clientId: 0,
  updatingClient: false,
  currentClient: {},
};

const clientsReducer = (state = initialState, action) => {
  // debugger
  switch (action.type) {
    case actionTypes.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        allclients: action.payload.allclients,
      };

    case actionTypes.SAVE_MY_CLIENTS_SUCCESS:
      return {
        ...state,
        myclients: action.payload.myclients,
      };
    case actionTypes.SAVE_CURRENT_CLIENT_SUCCESS:
      return {
        ...state,
        currentClient: action.payload.currentClient,
      };
    case actionTypes.SAVE_MY_CLIENT_ID_SUCCESS:
      return {
        ...state,
        clientId: action.payload.clientId,
      };
    case actionTypes.UPDATING_CLIENT_ID_SUCCESS:
      return {
        ...state,
        updatingClient: action.payload.updatingClient,
      };

    default:
      return state;
  }
};

export default clientsReducer;
