import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import windowSize from 'react-window-size';

import NavLogo from './NavLogo';
import NavContent from './NavContent';
import OutsideClick from './OutsideClick';
import Aux from '../hoc/_Aux'
import actionTypes, { savemyClientId, updatingClient, saveMyCurrentPermissions, saveCurrentClient, saveCurrentUrl } from '../../logic/actions/actionTypes';
import navigation from '../../menu-items';
import NiceSelect from 'components/Input/NiceSelect';
import { appHelpers } from 'appHelpers';
import { permissionstuff } from 'permissionstuff';

class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: {
                items: []
            }
        }
    }
    resize = () => {
        const contentWidth = document.getElementById('root').clientWidth;

        if (this.props.layout === 'horizontal' && contentWidth < 992) {
            this.props.onChangeLayout('vertical');
        }
    };

    componentDidMount() {
        if (this.props.codes.length > 0) {
            // debugger
            const items = navigation.items
            let r = permissionstuff.filterSideMenu(this.props.codes, items)
            console.log({ddfdf:r, items, codes: this.props.codes})
            // debugger
            if (r.length > 0) {
                this.setState({
                    navigation: {
                        items: r
                    }
                })
            } else {
                this.setState({
                    navigation: {
                        items: [
                            {
                                id: "navigation",
                                title: "Navigation",
                                type: "group",
                                icon: "icon-navigation",
                                children: [
                                    {
                                        id: "welcome",
                                        title: "Welcome",
                                        type: "item",
                                        url: "/home/welcome",
                                        classes: "nav-item",
                                        icon: "feather icon-home",
                                    },

                                ],
                            },
                            // {
                            //   id: "dashboard",
                            //   title: "Overview",
                            //   type: "item",
                            //   url: "/home/dashboard",
                            //   icon: "fa fa-tachometer",
                            // },
                            {
                                id: "clients",
                                title: "Client Info",
                                type: "item",
                                url: "/home/client-info",
                                icon: "fa fa-users",
                                code: "CanViewClient"
                            },
                        ]
                    }
                })
            }
        } else {
            appHelpers.alertError("You Have No Permissions, Please Contact an administrator")
            this.props.history.push("/")
        }
        this.resize();
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }


    onChangeOption = (e) => {
        // e.preventDefault();
        // e.persist();
        // debugger
        console.log("clientIdin index irght", this.props)
        console.log("event changed", e.target.value)
        const { clientId } = this.props
        if (parseInt(clientId) === parseInt(e.target.value)) {
            // do nothing
            return;
        } else {
            console.log("updating==")
            this.props.dispatch(savemyClientId(parseInt(e.target.value)))

            //debugger
            const newClientId = parseInt(e.target.value)
            // debugger
            this.upDateClientDetails(newClientId)
        }
    }


    upDateClientDetails = async (newClientId) => {
        this.props.dispatch(saveCurrentUrl(this.props.location.pathname))
        this.props.dispatch(updatingClient(true))
        const { Constants } = this.props
        //debugger
        // let permEncrypt = JSON.parse(
        //     localStorage.getItem("SERVICEMANAGER.CLIENT_IU_")
        //   );

        // let permDecrypt = appHelpers.cryptographyService().decryptObject(permEncrypt);
        let tkEncrypt = JSON.parse(
            localStorage.getItem("SERVICEMANAGER.CLIENT_UI_")
        );
        let tkDecrypt = appHelpers.cryptographyService().decrypt(tkEncrypt);
        const Authtoken = `Bearer ${tkDecrypt}`;
        // debugger
        await this.props
            .Service(Authtoken, this.props.history, newClientId)
            .getInfo(Constants.USER, Constants.GET_USER_INFO)
            .then(({ data }) => {
                const { responseData } = data

                let all = [];
                // debugger
                if (responseData.permissions) {
                    responseData.permissions.map((item, i) => {
                        return all.push(item.code);
                    });
                }
                const permissions = permissionstuff.isAuthorized(all);
                //    debugger
                this.props.dispatch(saveMyCurrentPermissions(permissions))
                this.props.dispatch(updatingClient(false))
                // const currentPath = this.props.location.pathname;
                let currentClient = this.props.myclients.filter(c => c.id === newClientId);
                this.props.dispatch(saveCurrentClient(currentClient[0]))
                this.props.history.push("/home")
                this.props.history.replace(this.props.currentUrl)
                //  debugger
            })
            .catch((err) => {
                // debugger;
                if (err) {
                    this.setState({ error: true, sending: false });
                }
            });
    }

    render() {
        let navClass = [
            'pcoded-navbar',
        ];
        const { navigation } = this.state
        console.log("client id upsated", this.state)
        if (this.props.preLayout !== null && this.props.preLayout !== '' && this.props.preLayout !== 'layout-6' && this.props.preLayout !== 'layout-8') {
            navClass = [...navClass, this.props.preLayout];
        } else {
            navClass = [
                ...navClass,
                this.props.layoutType,
                this.props.navBackColor,
                this.props.navBrandColor,
                'drp-icon-' + this.props.navDropdownIcon,
                'menu-item-icon-' + this.props.navListIcon,
                this.props.navActiveListColor,
                this.props.navListTitleColor,
            ];

            if (this.props.layout === 'horizontal') {
                navClass = [...navClass, 'theme-horizontal'];
            }

            if (this.props.navBackImage) {
                navClass = [...navClass, this.props.navBackImage];
            }

            if (this.props.navIconColor) {
                navClass = [...navClass, 'icon-colored'];
            }

            if (!this.props.navFixedLayout) {
                navClass = [...navClass, 'menupos-static'];
            }

            if (this.props.navListTitleHide) {
                navClass = [...navClass, 'caption-hide'];
            }
        }

        if (this.props.windowWidth < 992 && this.props.collapseMenu) {
            navClass = [...navClass, 'mob-open'];
        } else if (this.props.collapseMenu) {
            navClass = [...navClass, 'navbar-collapsed'];
        }

        if (this.props.preLayout === 'layout-6') {
            document.body.classList.add('layout-6');
            document.body.style.backgroundImage = this.props.layout6Background;
            document.body.style.backgroundSize = this.props.layout6BackSize;
        }

        if (this.props.preLayout === 'layout-8') {
            document.body.classList.add('layout-8');
        }

        if (this.props.layoutType === 'dark') {
            document.body.classList.add('datta-dark');
        } else {
            document.body.classList.remove('datta-dark');
        }

        if (this.props.rtlLayout) {
            document.body.classList.add('datta-rtl');
        } else {
            document.body.classList.remove('datta-rtl');
        }

        if (this.props.boxLayout) {
            document.body.classList.add('container');
            document.body.classList.add('box-layout');
        } else {
            document.body.classList.remove('container');
            document.body.classList.remove('box-layout');
        }

        let navContent = (
            <div className="navbar-wrapper">
                <NavLogo collapseMenu={this.props.collapseMenu} windowWidth={this.props.windowWidth} onToggleNavigation={this.props.onToggleNavigation} />
                <NavContent navigation={navigation.items} />
            </div>
        );
        if (this.props.windowWidth < 992) {
            navContent = (
                <OutsideClick>
                    <div className="navbar-wrapper">
                        <NavLogo collapseMenu={this.props.collapseMenu} windowWidth={this.props.windowWidth} onToggleNavigation={this.props.onToggleNavigation} />
                        <NavContent navigation={navigation.items} />
                    </div>
                </OutsideClick>
            );
        }

        return (
            <Aux>

                <nav className={navClass.join(' ')}>
                    <NiceSelect data={this.props.myclients} value={this.props.clientId} onChangeOption={this.onChangeOption} />
                    {navContent}
                </nav>
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        layout: state.navigation.layout,
        preLayout: state.navigation.preLayout,
        collapseMenu: state.navigation.collapseMenu,
        layoutType: state.navigation.layoutType,
        navBackColor: state.navigation.navBackColor,
        navBackImage: state.navigation.navBackImage,
        navIconColor: state.navigation.navIconColor,
        navBrandColor: state.navigation.navBrandColor,
        layout6Background: state.navigation.layout6Background,
        layout6BackSize: state.navigation.layout6BackSize,
        rtlLayout: state.navigation.rtlLayout,
        navFixedLayout: state.navigation.navFixedLayout,
        boxLayout: state.navigation.boxLayout,
        navDropdownIcon: state.navigation.navDropdownIcon,
        navListIcon: state.navigation.navListIcon,
        navActiveListColor: state.navigation.navActiveListColor,
        navListTitleColor: state.navigation.navListTitleColor,
        navListTitleHide: state.navigation.navListTitleHide,
        currentUrl: state.navigation.currentUrl,
        myclients: state.clientsReducer.myclients,
        clientId: state.clientsReducer.clientId
    }
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch, // ← Add this
        onToggleNavigation: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
        onChangeLayout: (layout) => dispatch({ type: actionTypes.CHANGE_LAYOUT, layout: layout }),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(windowSize(Navigation)));
