import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";

import "../../../assets/scss/style.scss";
import Aux from "../../../components/hoc/_Aux";
import Breadcrumb from "../../../components/Breadcrumb";
import { setupAccountErrors } from "validation";
import CustomInput from "components/Input/CustomInput";
import { Checkbox, CircularProgress, Grid } from "@material-ui/core";
import { appHelpers } from "appHelpers";
import CustomPhoneInput from "components/Input/CustomPhoneInput";
import CustomCountrySelect from "components/Input/CustomCountrySelect";

const NewAccount = (props) => {
  const hasError = useRef(null);
  const hasErrortwo = useRef(null);

  const [data, setData] = useState({
    ClientName: "",
    ClientCode: "",
    ClientAgree: false,
    ClientCountry: "",
    ClientFirstName: "",
    ClientLastName: "",
    ClientEmail: "",
    ClientPhone: "",
    ClientRefCode: "",
  });
  const [err, setError] = useState(setupAccountErrors);
  const [isSuccess, setisSuccess] = useState(false);
  const [loading, setloading] = useState(false);
  const [disableSetup, setdisableSetup] = useState(false);
  const [errorText, seterrorText] = useState("");
  const [isError, setisError] = useState(false);
  const [setdisableCreate] = useState(false);
  const [step, setstep] = useState(1);

  const handleCheckboxInputChange =
    (input) =>
    ({ target: { checked } }) => {
      setData((prevState) => ({
        ...prevState,
        [input]: checked,
      }));
      props.validator(
        { name: input, value: checked },
        "NewAccount",
        setError,
        err
      );
    };
  const setup = () => {
    //
    setdisableSetup(true);
    setloading(true);
    seterrorText("");
    const { Constants } = props;
    props.validatorAll(
      [
        { name: "ClientCountry", value: data.ClientCountry, label: "Country" },
        { name: "ClientPhone", value: data.ClientPhone, label: "Phone" },
      ],
      "NewAccount",
      setError,
      err,
      hasErrortwo
    );
    if (!hasErrortwo.current) {
      setloading(false);
      seterrorText("");
      setdisableSetup(false);
      return;
    }
    if (Object.keys(hasErrortwo.current).length > 0) {
      setloading(false);
      setdisableSetup(false);
      return;
    }
    const payload = {
      client: {
        name: data.ClientName,
        code: `${data.ClientName}${appHelpers.genClientRef(3)}`,
        useAML: true,
        callbackUrl: "https://google.com",
        kycLevel: 1,
        countryId: "",
        stateId: "",
        incorporationNumber: appHelpers.randomNum(4).toString(),
      },
      user: {
        firstName: data.ClientFirstName,
        lastName: data.ClientLastName,
        email: data.ClientEmail,
        phoneNumber: data.ClientPhone,
        roleIds: [1],
      },
    };
    const SetupAccountService = props.Service(null, null);

    SetupAccountService.createItemV1(payload, Constants.CLIENTS)
      .then((res) => {
        const { data } = res;
        if (data.requestSuccessful === true) {
          setdisableSetup(false);
          setloading(false);
          setisSuccess(true);
          appHelpers.alertSuccess("Registration Successful");

          clearFields();
          setstep(1);
        } else {
          setdisableSetup(false);
          setloading(false);
          setisError(true);
          seterrorText(data.message);
        }
      })
      .catch((err) => {
        console.log("Err, ", err.response);
        if (err.response === undefined) {
          setdisableSetup(false);
          setloading(false);
          setisError(true);
          seterrorText("Check Your Network and try again");
        }
        if (err.response) {
          setdisableSetup(false);
          setloading(false);
          setisError(true);
          seterrorText(
            err.response.data?.message ?? "Something happened, please try again"
          );
        }
      });
  };

  const handleStepTwo = () => {
    props.validatorAll(
      [
        { name: "ClientEmail", value: data.ClientEmail, label: "Email" },
        {
          name: "ClientFirstName",
          value: data.ClientFirstName,
          label: "firstName",
        },
        { name: "ClientName", value: data.ClientLastName, label: "lastName" },
        { name: "ClientLastName", value: data.ClientName, label: "Name" },
      ],
      "NewAccount",
      setError,
      err,
      hasError
    );
    if (!hasError.current) {
      return;
    }
    if (Object.keys(hasError.current).length > 0) {
      return;
    }

    setstep(2);
  };

  const handleBack = () => {
    setstep(1);
    setloading(false);
    setdisableCreate(false);
  };
  const handleCreateFormInputChange =
    (input, label) =>
    ({ target: { value } }) => {
      setData((prevState) => ({
        ...prevState,
        [input]: value,
      }));
      props.validator(
        { name: input, value: value, label: label },
        "NewAccount",
        setError,
        err
      );
    };

  const handleCreateFormPhoneInputChange = (value) => {
    setData((prevState) => ({
      ...prevState,
      ClientPhone: value,
    }));
    props.validator(
      { name: "ClientPhone", value: value, label: "Phone" },
      "NewAccount",
      setError,
      err
    );
  };

  const onSetCountry = (country) => {
    setData((prevState) => ({
      ...prevState,
      ClientCountry: country,
    }));
    props.validator(
      { name: "ClientCountry", value: country, label: "Country" },
      "ClientCountry",
      setError,
      err
    );
  };
  const clearFields = () => {
    setData({
      ClientName: "",
      ClientCode: "",
      ClientAgree: true,
      ClientcallbackUrl: "",
      ClientFirstName: "",
      ClientLastName: "",
      ClientEmail: "",
      ClientPhone: "",
    });
    setError(setupAccountErrors);
  };

  return (
    <Aux>
      <Breadcrumb />
      <div className="auth-wrapper" style={{ backgroundColor: "#013B4A" }}>
        <div className="auth-content">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <img
                  src={"/vigipay_black.svg"}
                  alt="Logo"
                  style={{ width: "190px", height: "80px" }}
                />
              </div>
              <h4 className="mt-2 mb-3 text-left">
                <b>Let's get you started with your Vigipay account</b>
              </h4>
              {isError && (
                <h6
                  className="text-danger text-center"
                  style={{ marginBottom: "2rem" }}
                >
                  {errorText}
                </h6>
              )}
              {isSuccess && (
                <h6
                  className="text-success text-center"
                  style={{ marginBottom: "2rem" }}
                >
                  Registration successful, please check you email to proceed
                </h6>
              )}

              {step === 1 && (
                <>
                  <CustomInput
                    value={data.ClientFirstName}
                    errorText={err.ClientFirstName}
                    showError={err.ClientFirstName.length > 0}
                    onChange={handleCreateFormInputChange(
                      "ClientFirstName",
                      "Firstname"
                    )}
                    id="Code"
                    labelText="Firstname"
                    placeholder="Firstname"
                    type={"text"}
                  />
                  <CustomInput
                    value={data.ClientLastName}
                    errorText={err.ClientLastName}
                    showError={err.ClientLastName.length > 0}
                    onChange={handleCreateFormInputChange(
                      "ClientLastName",
                      "Lastname"
                    )}
                    id="Code"
                    labelText="Lastname"
                    placeholder="Lastname"
                    type={"text"}
                  />
                  <CustomInput
                    value={data.ClientName}
                    errorText={err.ClientName}
                    showError={err.ClientName.length > 0}
                    onChange={handleCreateFormInputChange(
                      "ClientName",
                      "Client Name"
                    )}
                    id="eemail"
                    labelText="Business Name"
                    placeholder="Name"
                    type={"text"}
                  />

                  <CustomInput
                    value={data.ClientEmail}
                    errorText={err.ClientEmail}
                    showError={err.ClientEmail.length > 0}
                    onChange={handleCreateFormInputChange(
                      "ClientEmail",
                      "Email"
                    )}
                    id="Code"
                    labelText="Email"
                    placeholder="Email"
                    type={"text"}
                  />

                  <button
                    className="btn btn-primary shadow-2 mb-4 mainColor"
                    style={{ width: "100%" }}
                    id="setupbutton"
                    onClick={handleStepTwo}
                  >
                    Next ({step} of 2)
                  </button>
                  <p
                    className="mb-2 text-muted"
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    <NavLink to="/" style={{ color: "#2992D0" }}>
                      Login
                    </NavLink>
                  </p>
                </>
              )}
              <br />

              {step === 2 && (
                <>
                  <CustomCountrySelect
                    onSetCountry={onSetCountry}
                    errorText={err.ClientCountry}
                    showError={err.ClientCountry.length > 0}
                    className=""
                    searchable={true}
                    labelText="What country is your business based?"
                    value={data.ClientCountry}
                  />
                  <div className="mt-3"></div>
                  <CustomPhoneInput
                    labelText="Phone"
                    errorText={err.ClientPhone}
                    showError={err.ClientPhone.length > 0}
                    value={data.ClientPhone}
                    onChange={handleCreateFormPhoneInputChange}
                  />
                  <div className="mt-4"></div>
                  <CustomInput
                    value={data.ClientRefCode}
                    errorText={err.ClientRefCode}
                    showError={err.ClientRefCode.length > 0}
                    onChange={handleCreateFormInputChange(
                      "ClientRefCode",
                      "Email"
                    )}
                    id="Code"
                    labelText="Got a referral code (optional)"
                    placeholder="Code "
                    type={"text"}
                  />
                  <br />
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <div>
                        <Checkbox
                          checked={data.ClientAgree}
                          onChange={handleCheckboxInputChange("ClientAgree")}
                          name="ClientAgree"
                          style={{
                            color: "#04A8F4",
                            padding: "2px",
                          }}
                        />
                        I acknowledge that I have read and do hereby accept the
                        terms and conditions in the Vigipay's Terms of Use,
                        Merchant Agreement and Privacy Policy
                      </div>
                    </form>{" "}
                  </Grid>
                  <div className="mt-3"></div>
                  <div
                    className="d-flex mt-2"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div>
                      <button
                        className="btn btn-primary shadow-2 mb-4 mainColor"
                        style={{ width: "100%" }}
                        id="backbutton"
                        onClick={handleBack}
                      >
                        Back
                      </button>
                    </div>
                    &nbsp; &nbsp;
                    <div>
                      <button
                        className="btn btn-primary shadow-2 mb-4 mainColor"
                        style={{ width: "100%" }}
                        disabled={disableSetup || data.ClientAgree !== true}
                        id="setupbutton"
                        onClick={setup}
                      >
                        {loading && (
                          <CircularProgress
                            size={20}
                            style={{ marginBottom: "-4px" }}
                            color="white"
                          />
                        )}{" "}
                        Submit
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Aux>
  );
};

export default NewAccount;
