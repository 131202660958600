import actionTypes from "../actions/actionTypes";

const initialState = {
  services: [],
};

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload.services,
      };

    default:
      return state;
  }
};

export default servicesReducer;
