import actionTypes from "../actions/actionTypes";

const initialState = {
  configurations: [],
};

const configurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        configurations: action.payload.configurations,
      };
    default:
      return state;
  }
};

export default configurationReducer;
