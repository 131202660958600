import React from "react";
import ReactFlagsSelect from "react-flags-select";
import { Form } from "react-bootstrap";

const CustomCountrySelect = ({ labelText, onSetCountry, value, searchable, errorText, showError, className }) => {
  console.log("country flag", showError);
  return (
    <div>
      <div>
        <Form.Label>{labelText}</Form.Label>
      </div>
      <ReactFlagsSelect
        selected={value}
        countries={["US", "GB", "FR", "DE", "IT", "NG"]}
        onSelect={onSetCountry}
        className={showError ? className.concat(" roles-select-error") : className}
        searchable={searchable}
      />
      {showError && (
        <Form.Control.Feedback type={"invalid"} style={{ display: "block", marginBottom: "-1rem", textAlign: "left" }}>
          {errorText}
        </Form.Control.Feedback>
      )}
    </div>
  );
};

export default CustomCountrySelect;
