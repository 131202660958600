import actionTypes from "../actions/actionTypes";

const initialState = {
  gateways: [],
  gatewayCredentialkeys: [],
  gatewaysbyclient: [],
};

const gatewayReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GATEWAYS_SUCCESS:
      return {
        ...state,
        gateways: action.payload.gateways,
      };
    case actionTypes.GET_GATEWAY_CREDENTIAL_KEY_SUCCESS:
      return {
        ...state,
        gatewayCredentialkeys: action.payload.gatewayCredentialkeys,
      };
    case actionTypes.GET_GATEWAY_ACCOUNTS_BY_CLIENT:
      return {
        ...state,
        gatewaysbyclient: action.payload.gatewaysbyclient,
      };

    default:
      return state;
  }
};

export default gatewayReducer;
