import React from "react";
import Wrapper from "../Wrapper/Wrapper";
import * as CommonFunctions from "CommonFunctons";
import { appHelpers } from "appHelpers";
import { connect } from "react-redux";
import { saveUserData, saveMyClientsData, savemyClientId, saveMyCurrentPermissions } from "logic/actions/actionTypes";
import { permissionstuff } from "permissionstuff";
import { ErrorPage } from "Pages/NotFound/ErrorPage";
import PreLoader from "components/Loader/PreLoader";
import CustomBackDrop from "components/Loader/CustomBackDrop";
import IdleTimer from "react-idle-timer";
import Swal from "sweetalert2";

const LOCATIONSTORAGEKEY = "clientconfigurationNavigationKey";

class AuthenticatedLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: { userobj: {} },
      token: "",
      sending: false,
      page: {},
      showSideBar: false,
      permissions: [],
      ServiceBase: null,
      ExportServiceBase: null,
      error: false,
      unAuthorized: null,
      expiresIn: parseInt(this.props.expiresIn),
    };
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  componentWillMount() {
    // check if the user is authenticated. e.g if the token exist in localStorage or any where else
    // if token doesn't exist, reroute to login
  }
  componentDidUpdate() { }
  async componentDidMount() {
    this.setState({ sending: true, error: false });
    const { clientId } = this.props;
    let timeEncrypt = JSON.parse(localStorage.getItem("SERVICEMANAGER.UI--_"));
    if (timeEncrypt && timeEncrypt !== null) {
      let timeDecrypt = appHelpers.cryptographyService().decrypt(timeEncrypt);
      this.setState({ expiresIn: parseInt(timeDecrypt) });
    }
    // debugger;
    // picking the first item in the clients list by default
    // debugger;
    // let firstClientId = firstClient.id;
    let firstClientId = clientId;
    this.props.dispatch(savemyClientId(firstClientId));
    let tkEncrypt = JSON.parse(localStorage.getItem("SERVICEMANAGER.CLIENT_UI_"));
    let permEncrypt = JSON.parse(localStorage.getItem("SERVICEMANAGER.CLIENT_IU_"));
    console.log({tkEncrypt, permEncrypt})
    if (tkEncrypt && permEncrypt && tkEncrypt !== null && tkEncrypt !== undefined && permEncrypt !== undefined) {
      let tkDecrypt = appHelpers.cryptographyService().decrypt(tkEncrypt);
      let permDecrypt = appHelpers.cryptographyService().decryptObject(permEncrypt);

      const Authtoken = `Bearer ${tkDecrypt}`;
      // let user = {};
      const { Constants } = this.props;
      let all = [];
      if (permDecrypt) {
        permDecrypt.map((item, i) => {
          return all.push(item.code||item.Value);
        });
      }
      console.log({allpermission: all})
      const clientNavigationKey = localStorage.getItem(LOCATIONSTORAGEKEY);
      const realKeyValue = parseInt(clientNavigationKey);
      // if for instance on clients nav-- the user is on terminal tab..
      // terminal tab has a number..
      // and then the user goes to the browser and types /home/clients
      // then we want to set the client navigation key index
      if (realKeyValue) {
        if (this.props.location.pathname === "/home/configuration" || (this.props.location.pathname === "/home/configuration/" && clientNavigationKey !== -1)) {
          localStorage.setItem(LOCATIONSTORAGEKEY, -1);
          this.props.history.push("/home/configuration");
        }
      } else {
        localStorage.setItem(LOCATIONSTORAGEKEY, clientNavigationKey);
      }
      const permissions = permissionstuff.isAuthorized(all);
      // debugger
      await this.props
        .Service(Authtoken, this.props.history, firstClientId)
        .getUserClients(Constants.USER, Constants.GET_USER_CLIENTS)
        .then(res => {
          const { data } = res;

          this.props.dispatch(saveMyClientsData(data.responseData));
          this.props
            .Service(Authtoken, this.props.history, firstClientId)
            .getInfo(Constants.USER, Constants.GET_USER_INFO)
            .then(res => {
              const { data } = res;
              if(data.responseData){
                this.props.dispatch(saveUserData(data.responseData));
              }
              // update the user data and myclients data to the redux store from redux persist
              this.setState(
                {
                  ServiceBase: this.props.Service(Authtoken, this.props.history, this.props.clientId),
                  ExportServiceBase: this.props.ExportService(Authtoken, this.props.history, this.props.clientId),
                  validator: CommonFunctions.validator,
                  validatorAll: CommonFunctions.validatorAll,
                  permissions,
                  codes: all,
                },
                () => {
                  this.setState({ sending: false, error: false });
                }
              );
              // update the permissions in redux too
              this.props.dispatch(saveMyCurrentPermissions(permissions));
            })
            .catch(err => {
              // debugger;
              if (err) {
                if (err.response && err.response.status === 401) {
                  this.setState({ unAuthorized: true });
                }
                this.setState({ error: true, sending: false });
              }
            });
        })
        .catch(err => {
          if (err) {
            if (err.response && err.response.status === 401) {
              this.setState({ unAuthorized: true });
            }
            this.setState({ error: true, sending: false });
          }
        });
    } else {
      appHelpers.alertError("No Authorization Provided, please login again");
      this.props.history.push("/");
    }
  }

  logout = () => {
    // this doesn't work
    this.setState({ sending: true }, () => {
      setTimeout(() => {
        localStorage.clear();
        this.props.history.push("/");
      }, 1000);
    });
  };

  handleOnIdle(event) {
    //alert("you're gonna be logged out")
    let timerInterval;
    Swal.fire({
      // title: 'Session Expired!',
      // html: 'Locking you out in <b></b> milliseconds.',
      // timer: 50000,
      // allowEscapeKey: false,
      // allowOutsideClick: false,
      // timerProgressBar: true,
      // confirmButtonText:'<span >Okay</span>',
      // onBeforeOpen: () => {
      //   Swal.showLoading();
      //   timerInterval = setInterval(() => {
      //     const content = Swal.getContent()
      //     if (content) {
      //       const b = content.querySelector('b')
      //       if (b) {
      //         b.textContent = Swal.getTimerLeft()
      //       }
      //     }
      //   }, 100)
      // },
      //   onClose: () => {
      //     clearInterval(timerInterval)
      //   }
      title: "Session is about to expire",
      html: "Locking you out in <b></b> seconds, <p> <br><b> would you like to keep it ?</b></p>",
      timer: 31 * 1000,
      type: "warning",
      reverseButtons: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "No, logout!",
      cancelButtonText: "Yes, Extend it!",
      onBeforeOpen: () => {
        // Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Swal.getTimerLeft().toString().substr(0, 2);
            }
          }
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    }).then(result => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("I was closed by the timer");
        localStorage.clear();
        this.props.history.push("/");
      }
      if (result.isConfirmed) {
        localStorage.clear();
        this.props.history.push("/");
      }
    });
    console.log("last active", this.idleTimer.getLastActiveTime());
  }

  goHome() {
    localStorage.clear();
    this.props.history.push("/");
  }

  handleOnAction(event) { }

  handleOnActive(event) {
    console.log("time remaining", this.idleTimer.getRemainingTime());
  }

  render() {
    const { expiresIn } = this.state;
    return (
      <div>
        {this.props.updatingClient === true && <CustomBackDrop />}
        {this.state.error && <ErrorPage {...this.props} unAuthorized={this.state.unAuthorized} />}
        {this.state.sending && (
          <div>
            <PreLoader />
          </div>
        )}
        {!this.state.sending && this.state.ServiceBase && this.state.ExportServiceBase && (
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            timeout={expiresIn * 1000} // convert the secconds value from the api to ms
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            onAction={this.handleOnAction}
            debounce={250}
          >
            {" "}
            <Wrapper {...this.state} {...this.props} />
          </IdleTimer>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    expiresIn: state.authReducer.expiresIn,
    userdata: state.authReducer.userdata,
    myclients: state.clientsReducer.myclients,
    updatingClient: state.clientsReducer.updatingClient,
    clientId: state.clientsReducer.clientId,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch, // ← Add this
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedLayout);
