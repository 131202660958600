import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import Layout from "./components/hoc/Layout";
import * as serviceWorker from "./serviceWorker";
// import store from './logic/store';
import configuration from "./configuration";
import * as Constants from "./Constants";
import { Service } from "./Services";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ExportService } from "./ExportService";
import { PersistGate } from "redux-persist/integration/react";
import returnStoreAndPersistor from "./logic/configureStore";
import history from "./history";
import "./custommodalstyle.css";
import "./index.css";
// import 'react-date-range/dist/styles.css'; // main css file
// import 'react-date-range/dist/theme/default.css'; // theme css file

const { store } = returnStoreAndPersistor();
const { persistor } = returnStoreAndPersistor();

// import 'bootstrap/dist/css/bootstrap.min.css';

const instance = Axios.create({
  baseURL: "",
  headers: {},
});

instance
  .get("/config.json")
  .then(res => {
    if (res.data && res.data.SERVICEBASEURI) {
      const config = res.data;
      const serviceUrl = process.env.NODE_ENV !== "development" ? window.env?.SERVICEBASEURI || config?.SERVICEBASEURI: 'https://vgnpc-servicemanager-client-dev.test.vggdev.com/api/v1/'
      console.log({serviceUrl})
      sessionStorage.setItem("ApiBaseUrl", serviceUrl);

      ReactDOM.render(
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history} basename={configuration.basename}>
              <>
                <Layout Constants={Constants} Service={Service.bind(null, serviceUrl, Axios)} ExportService={ExportService.bind(null, serviceUrl, Axios)} />
                <ToastContainer style={{ width: "500px" }} limit={1} />
              </>
            </Router>
          </PersistGate>
        </Provider>,
        document.getElementById("root")
      );

      serviceWorker.unregister();
    } else {
      console.log("Error:", "base API URL is missing..");
    }
  })
  .catch(err => {});
function noop() {}

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
