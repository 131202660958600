import { appHelpers } from "appHelpers";

const actionTypes = {
  APP_LOAD: "APP_LOAD",
  BEGIN_REQUEST: "BEGIN_REQUEST",
  COLLAPSE_MENU: "COLLAPSE_MENU",
  COLLAPSE_TOGGLE: "COLLAPSE_TOGGLE",
  FULL_SCREEN: "FULL_SCREEN",
  FULL_SCREEN_EXIT: "FULL_SCREEN_EXIT",
  CHANGE_LAYOUT: "CHANGE_LAYOUT",
  NAV_CONTENT_LEAVE: "NAV_CONTENT_LEAVE",
  NAV_COLLAPSE_LEAVE: "NAV_COLLAPSE_LEAVE",
  UPDATE_NAV_HEADER_COLOR: "UPDATE_NAV_HEADER_COLOR",
  CHANGE_TOP_NAV_MENU_ITEM: "CHANGE_TOP_NAV_MENU_ITEM",
  TOGGLE_CHART_AND_DATA_UI: "TOGGLE_CHART_AND_DATA_UI",
  GET_MICROSERVICES_SUCCESS: "GET_MICROSERVICES_SUCCESS",
  GET_PAYMENT_SERVICE_GROUPS_SUCCESS: "GET_PAYMENT_SERVICE_GROUPS_SUCCESS",
  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  GET_CLIENTSERVICES_SUCCESS: "GET_CLIENTSERVICES_SUCCESS",
  GET_CLIENT_CONFIGURATIONS:"GET_CLIENT_CONFIGURATIONS",
  GET_GATEWAYS_SUCCESS:"GET_GATEWAYS_SUCCESS",
  GET_GATEWAY_CREDENTIAL_KEY_SUCCESS:"GET_GATEWAY_CREDENTIAL_KEY_SUCCESS",
  GET_CLIENTS_SUCCESS:"GET_CLIENTS_SUCCESS",
  GET_SERVICES_SUCCESS:"GET_SERVICES_SUCCESS",
  GET_ALL_CONFIGURATIONS_SUCCESS:"GET_ALL_CONFIGURATIONS_SUCCESS",
  GET_RESPONSE_CODE_SUCCESS: "GET_RESPONSE_CODE_SUCCESS",
  GET_GATEWAY_ACCOUNTS_BY_CLIENT:"GET_GATEWAY_ACCOUNTS_BY_CLIENT",
  GET_CURRENCY_PAIR_SUCCESS: "GET_CURRENCY_PAIR_SUCCESS",
  GET_CURRENCY_SUCCESS:"GET_CURRENCY_SUCCESS",
  GET_PERMISSIONS_SUCCESS:"GET_PERMISSIONS_SUCCESS",
  SAVE_MY_CLIENTS_SUCCESS:"SAVE_MY_CLIENTS_SUCCESS",
  SAVE_MY_CLIENT_ID_SUCCESS:"SAVE_MY_CLIENT_ID_SUCCESS",
  SAVE_USER_SUCCESS:"SAVE_USER_SUCCESS",
  UPDATING_CLIENT_ID_SUCCESS:"UPDATING_CLIENT_ID_SUCCESS",
  SAVE_CURRENT_PERMISSIONS_SUCCESS:"SAVE_CURRENT_PERMISSIONS_SUCCESS",
  SAVE_CURRENT_CLIENT_SUCCESS:"SAVE_CURRENT_CLIENT_SUCCESS",
  SAVE_CURRENT_URL_SUCCESS:"SAVE_CURRENT_URL_SUCCESS",
  GET_ROLES_SUCCESS:"GET_ROLES_SUCCESS",
  SET_EXPIRATION:"SET_EXPIRATION",
  GET_KYC_LEVELS_SUCCESS:"GET_KYC_LEVELS_SUCCESS",
  GET_GATEWAY_CHARGE_BEARER_SUCCESS:"GET_GATEWAY_CHARGE_BEARER_SUCCESS",
  GET_BANK_SUCCESS:"GET_BANK_SUCCESS",
  GET_SETTLEMENT_PROFILE_SUCCESS:"GET_SETTLEMENT_PROFILE_SUCCESS",
  GET_CHARGE_TYPES_SUCCESS:"GET_CHARGE_TYPES_SUCCESS",
  SAVE_CURRENT_CONFIGURATION_SUCCESS:"SAVE_CURRENT_CONFIGURATION_SUCCESS"
};

// add as many actionTypes as necessary. (But be careful of memory leakages)
export const savecurentConfigurationSuccess = (currentConfiguration) => ({
  type: actionTypes.SAVE_CURRENT_CONFIGURATION_SUCCESS,
  payload: { currentConfiguration },
});
export const saveexpirationSuccess = (expiresIn) => ({
  type: actionTypes.SET_EXPIRATION,
  payload: { expiresIn },
});
export const saveuserDataSuccess = (userdata) => ({
  type: actionTypes.SAVE_USER_SUCCESS,
  payload: { userdata },
});
export const savemyClientsSuccess = (myclients) => ({
  type: actionTypes.SAVE_MY_CLIENTS_SUCCESS,
  payload: { myclients },
});


export const saveCurrentUrlSuccess = (currentUrl) => ({
  type: actionTypes.SAVE_CURRENT_URL_SUCCESS,
  payload: { currentUrl },
});
export const savecurrentClientSuccess = (currentClient) => ({
  type: actionTypes.SAVE_CURRENT_CLIENT_SUCCESS,
  payload: { currentClient },
});

export const saveMyCurrentPermissionsSuccess = (currentpermissions) => ({
  type: actionTypes.SAVE_CURRENT_PERMISSIONS_SUCCESS,
  payload: { currentpermissions },
});
export const updatingClientSuccess = (updatingClient) => ({
  type: actionTypes.UPDATING_CLIENT_ID_SUCCESS,
  payload: { updatingClient },
});
export const savemyClientIdSuccess = (clientId) => ({
  type: actionTypes.SAVE_MY_CLIENT_ID_SUCCESS,
  payload: { clientId },
});
export const fetchPermissionsSuccess = (allpermissions) => ({
  type: actionTypes.GET_PERMISSIONS_SUCCESS,
  payload: { allpermissions },
});

export const fetchCurrencySuccess = (currency) => ({
  type: actionTypes.GET_CURRENCY_SUCCESS,
  payload: { currency },
});
export const fetchCurrencyPairSuccess = (currencypair) => ({
  type: actionTypes.GET_CURRENCY_PAIR_SUCCESS,
  payload: { currencypair },
});

export const fetchGatewayAccountsByClientSuccess = (gatewaysbyclient) => ({
  type: actionTypes.GET_GATEWAY_ACCOUNTS_BY_CLIENT,
  payload: { gatewaysbyclient },
});

export const fetchAllResponseCodeSuccess = (responseCodes) => ({
  type: actionTypes.GET_RESPONSE_CODE_SUCCESS,
  payload: { responseCodes },
});

export const fetchAllConfigurationsSuccess = (configurations) => ({
  type: actionTypes.GET_ALL_CONFIGURATIONS_SUCCESS,
  payload: { configurations },
});
export const fetchRolesSuccess = (allroles) => ({
  type: actionTypes.GET_ROLES_SUCCESS,
  payload: { allroles },
});

export const fetchServicesSuccess = (services) => ({
  type: actionTypes.GET_SERVICES_SUCCESS,
  payload: { services },
});

export const fetchClientsSuccess = (allclients) => ({
  type: actionTypes.GET_CLIENTS_SUCCESS,
  payload: { allclients },
});
export const fetchGatewayCredentialKeysSuccess = (gatewayCredentialkeys) => ({
  type: actionTypes.GET_GATEWAY_CREDENTIAL_KEY_SUCCESS,
  payload: { gatewayCredentialkeys },
});

export const fetchGatewaysSuccess = (gateways) => ({
  type: actionTypes.GET_GATEWAYS_SUCCESS,
  payload: { gateways },
});

export const fetchClientConfigurationsSuccess = (clientConfigurations) => ({
  type: actionTypes.GET_CLIENT_CONFIGURATIONS,
  payload: { clientConfigurations },
});

export const fetchMicroServicesSuccess = (microservices) => ({
  type: actionTypes.GET_MICROSERVICES_SUCCESS,
  payload: { microservices },
});
export const fetchPaymentServiceGroupsSuccess = (servicegroups) => ({
  type: actionTypes.GET_PAYMENT_SERVICE_GROUPS_SUCCESS,
  payload: { servicegroups },
});
export const fetchCountriesSuccess = (countries) => ({
  type: actionTypes.GET_COUNTRIES_SUCCESS,
  payload: { countries },
});
export const fetchClientServicesSuccess = (clientservices) => ({
  type: actionTypes.GET_CLIENTSERVICES_SUCCESS,
  payload: { clientservices },
});
export const fetchKycLevelsSuccess = (kyclevels) => ({
  type: actionTypes.GET_KYC_LEVELS_SUCCESS,
  payload: { kyclevels },
});
export const fetchGatewayChargeBearerSuccess = (gatewaychargebearer) => ({
  type: actionTypes.GET_GATEWAY_CHARGE_BEARER_SUCCESS,
  payload: { gatewaychargebearer },
});
export const fetchBankSuccess = (bank) => ({
  type: actionTypes.GET_BANK_SUCCESS,
  payload: { bank },
});
export const fetchSettlementProfileSuccess = (settlementProfile) => ({
  type: actionTypes.GET_SETTLEMENT_PROFILE_SUCCESS,
  payload: { settlementProfile },
});
export const fetchChargeTypeSuccess = (chargeTypes) => ({
  type: actionTypes.GET_CHARGE_TYPES_SUCCESS,
  payload: { chargeTypes },
});
// Redux Actions Api Calls


// Redux Actions Api Calls



export function fetchAllChargeTypes(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.SPLIT_CONFIGURATION, Constants.GET_CHARGE_TYPE, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchChargeTypeSuccess(data.responseData));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}


export function fetchAllSettlementProfiles(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.SETTLEMENT_PROFILE, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchSettlementProfileSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}


export function fetchAllBanks(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.BANK, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchBankSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}



export function fetchAllGatewayChargeBearer(ServiceBase, Constants, history,searchData) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.SETTLEMENT_PROFILE, Constants.GET_GATEWAY_CHARGE_BEARER, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchGatewayChargeBearerSuccess(data.responseData));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllPermissions(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.PERMISSIONS, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchPermissionsSuccess(data.responseData));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllKycLevels(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.KYC_SERVICE_LEVEL, Constants.GET_KYC_LEVELS, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchKycLevelsSuccess(data.responseData));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllCurrency(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.CURRENCY, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchCurrencySuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllCurrencyPair(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.CURRENCY_PAIR, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchCurrencyPairSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}
export function fetchAllResponseCode(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.RESPONSE_CODE, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchAllResponseCodeSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllServices(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.SERVICES, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchServicesSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllClients(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.CLIENTS, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchClientsSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchGatewayCredentialKeys(ServiceBase, Constants, history, id,setloadingCredentialKeys,setGatewayAccountCredentials) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(Constants.GATEWAY_CREDENTIAL_KEY, Constants.GET_BY_GATEWAY_ID, 1000, 1, id)

        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              if(setGatewayAccountCredentials){
              setGatewayAccountCredentials(appHelpers.formatCredentialKeys(data.responseData.items))
              }
              dispatch(fetchGatewayCredentialKeysSuccess(data.responseData.items));
              if(setloadingCredentialKeys){
              setloadingCredentialKeys(false)
              }
            }
           
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchGatewayCredentialKeysServiceIdGatewayId(ServiceBase, Constants, history, idOne,idTwo,setloadingCredentialKeys,setGatewayAccountCredentials) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsByTwoIds(Constants.GATEWAY_CREDENTIAL_KEY, Constants.GET, 1000, 1, idOne,idTwo)

        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              if(setGatewayAccountCredentials){
              setGatewayAccountCredentials(appHelpers.formatCredentialKeys(data.responseData.items))
              }
              dispatch(fetchGatewayCredentialKeysSuccess(data.responseData.items));
              if(setloadingCredentialKeys){
              setloadingCredentialKeys(false)
              }
            }
           
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}



export function fetchGateways(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.GATEWAYS,
        Constants.GET_ALL,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchGatewaysSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchClientConfigurations(ServiceBase, Constants, history,clientId) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(Constants.CONFIGURATION, Constants.GET_ALL_CONFIGURATIONS_BY_CLIENT_ID, 1000, 1, clientId)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchClientConfigurationsSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}


export function fetchClientServices(ServiceBase, Constants, history,clientId,callback) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(Constants.CLIENT_SERVICES, Constants.GET_ALL_BY_CLIENT_ID, 1000, 1, clientId)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchClientServicesSuccess(data.responseData.items));
              if(callback){
                callback()
              }
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}
export function fetchGatewayAccountsByClient(ServiceBase, Constants, history,clientId) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(Constants.GATEWAY_ACCOUNT, Constants.GET_ALL_BY_CLIENT_ID, 1000, 1, clientId)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchGatewayAccountsByClientSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchGatewayAccountsByClientV2(ServiceBase, Constants, history,clientId) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(Constants.GATEWAY_ACCOUNT, Constants.GET_ALL, 1000, 1, clientId)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchGatewayAccountsByClientSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllConfigurations(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.CONFIGURATION, Constants.GET_ALL, 1000, 1,)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchAllConfigurationsSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllRoles(ServiceBase, Constants, history,searchData) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsFilter(Constants.ROLES, Constants.GET_ALL, 1000, 1,searchData)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchRolesSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}
export function fetchCountries(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.COUNTRY, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchCountriesSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchMicroServices(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.MICRO_SERVICES,
        Constants.GET_ALL,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchMicroServicesSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchPaymentServiceGroups(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.SERVICE_GROUPS,
        Constants.GET_ALL,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(
                fetchPaymentServiceGroupsSuccess(data.responseData.items)
              );
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

//  other dispatches
export function saveExpiration(expiresIn) {
  return (dispatch) => {
    return (
      dispatch(saveexpirationSuccess(expiresIn))
    );
  };
}
export function saveUserData(data) {
  console.log({userdata: data})
  return (dispatch) => {
    return (
      dispatch(saveuserDataSuccess(data))
    );
  };
}

export function saveMyClientsData(data) {
  return (dispatch) => {
    return (
      dispatch(savemyClientsSuccess(data))
    );
  };
}
export function savemyClientId(clientId) {
  return (dispatch) => {
    return (
      dispatch(savemyClientIdSuccess(clientId))
    );
  };
}

export function updatingClient(updatingClient) {
  return (dispatch) => {
    return (
      dispatch(updatingClientSuccess(updatingClient))
    );
  };
}

export function saveMyCurrentPermissions(currentpermissions) {
  return (dispatch) => {
    return (
      dispatch(saveMyCurrentPermissionsSuccess(currentpermissions))
    );
  };
}

export function saveCurrentClient(currentClient) {
  return (dispatch) => {
    return (
      dispatch(savecurrentClientSuccess(currentClient))
    );
  };
}

export function saveCurrentUrl(currentUrl) {
  return (dispatch) => {
    return (
      dispatch(saveCurrentUrlSuccess(currentUrl))
    );
  };
}

export function handlesavecurentConfiguration(currentConfiguration) {
  return (dispatch) => {
    return (
      dispatch(savecurentConfigurationSuccess(currentConfiguration))
    );
  };
}
export default actionTypes;
