export const permissionstuff = {
  isAuthorized: all => {
    // debugger
    const permissions = {
      CanUpdateConfiguration: all.includes("CanUpdateConfiguration") ? true : false,

      CanViewBankDestination: all.includes("CanViewBankDestination") ? true : false,

      // client gateway microservice
      CanCreateBankDestination: all.includes("CanCreateBankDestination") ? true : false,

      CanUpdateBankDestination: all.includes("CanUpdateBankDestination") ? true : false,
      CanViewGatewayAccount: all.includes("CanViewGatewayAccount") ? true : false,

      CanCreateGatewayAccount: all.includes("CanCreateGatewayAccount") ? true : false,

      CanCreateConfiguration: all.includes("CanCreateConfiguration") ? true : false,
      CanUpdateGatewayAccount: all.includes("CanUpdateGatewayAccount") ? true : false,

      CanCreateGatewayAccountCurrencyPair: all.includes("CanCreateGatewayAccountCurrencyPair") ? true : false,

      CanUpdateGatewayAccountCurrencyPair: all.includes("CanUpdateGatewayAccountCurrencyPair") ? true : false,

      CanViewTransaction: all.includes("CanViewTransaction") ? true : false,

      CanCreateTransaction: all.includes("CanCreateTransaction") ? true : false,

      CanViewUser: all.includes("CanViewUser") ? true : false,

      // client service
      CanViewClientService: all.includes("CanViewClientService") ? true : false,
      CanCreateClientService: all.includes("CanCreateClientService") ? true : false,

      // client service
      CanViewSettlementProfile: all.includes("CanViewSettlementProfile") ? true : false,
      CanCreateSettlementProfile: all.includes("CanCreateSettlementProfile") ? true : false,
      CanUpdateSettlementProfile: all.includes("CanUpdateSettlementProfile") ? true : false,

      CanCreateUser: all.includes("CanCreateUser") ? true : false,
      CanUpdateUser: all.includes("CanUpdateUser") ? true : false,

      CanViewGatewayAccountCurrencyPair: all.includes("CanViewGatewayAccountCurrencyPair") ? true : false,

      CanViewConfiguration: all.includes("CanViewConfiguration") ? true : false,

      // Client
      CanViewClient: all.includes("CanViewClient") ? true : false,
      CanUpdateClient: all.includes("CanUpdateClient") ? true : false,

      CanViewDashboard: all.includes("CanViewDashboard") ? true : false,

      //service
      CanViewService: all.includes("CanViewService") ? true : false,
    };
    // debugger
    return permissions;
  },

  filterSideMenu: (all, items) => {
    console.log({all, items})
    // debugger
    var res = items.filter(function f(o) {
      // if (o.permission && o.permission.includes("CanViewRole")) return true
      if (all.includes(o.code)) return true;

      if (o.children) {
        return (o.children = o.children.filter(f)).length;
      }

      return false;
    });
    // debugger

    return res;
  },
};
