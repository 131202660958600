import React from 'react';
import "./niceselect.scss"


export default function NiceSelect(props) {



	const renderDropdown = (items) =>{
		let dropdownElems = [];

		for (let i in items){
			let Id = parseInt(items[i].id)
		dropdownElems.push(
			<option key={i} style={{cursor:"pointer"}}  value={Id}>{items[i].name}</option>
		)
		}
		return dropdownElems
	}
    return (
       <div className="box select-dropdown">
	<select onClick={props.onChangeOption}  style={{cursor:"pointer"}} defaultValue={props.value}>
		{renderDropdown(props.data)}
	</select>
</div>
    );
}
