import React from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Form } from "react-bootstrap";

const CustomPhoneInput = props => {
  return (
    <div>
      <div>
        <Form.Label>{props.labelText}</Form.Label>
      </div>
      <ReactPhoneInput
        inputExtraProps={{
          name: "phone",
          required: true,
          autoFocus: true,
        }}
        country={"ng"}
        value={props.value}
        inputStyle={{
          height: "auto",
          width: "100%",
        }}
        containerStyle={{
          border: props.showError && "solid 1px #dc3545",
        }}
        dropdownStyle={{
          zIndex: "9999",
        }}
        inputClass="input-group mb-2"
        onChange={props.onChange}
      />
      {props.showError && (
        <Form.Control.Feedback type={"invalid"} style={{ display: "block", marginBottom: "-1rem", textAlign: "left" }}>
          {props.errorText}
        </Form.Control.Feedback>
      )}
    </div>
  );
};
export default CustomPhoneInput;
