// constants for api calls;
export const USER = "user";
export const GETLOGINUSER = "GETLOGINUSER";
export const USER_CREATE = "User";
export const GET_USER = "User";
export const PERMISSIONS = "Permissions";
export const CREATE = "Create";
export const CREATE_1 = "create";
export const CREATE_USER = "createuser";
export const CREATE_CLIENT = "createclient";
export const ACCOUNT = "Account";
export const LOGIN = "login";
export const SETUP_ACCOUNT = "SetupAccount";
export const AUTH = "auth";
export const RESET_PASSWORD = "ResetPassword";
export const API = "api";
export const USER_LIST = "userlist";
export const COUNT = "count";
export const COUNTRY = "Country";
export const GET_USER_BY_EMAIL = "GetUserByEmail";
export const SERVICE_GROUPS = "ServiceGroups";
export const GATEWAYS = "Gateways";
export const SERVICES = "Services";
export const CLIENTS = "Clients";
export const ROLES = "Roles";
export const VERIFY_USER = "VerifyUser";
export const MICRO_SERVICES = "Microservices";
export const CLIENT_SERVICES = "ClientServices";
export const CLIENT_GATEWAY_MICROSERVICES = "ClientGatewayMicroservices";
export const GET_ALL_BY_CLIENT_ID = "GetAllByClientId";
export const GET_ALL = "GetAll";
export const GET = "Get";
export const GET_ALL_TRANSACTIONS_BY_CLIENT_ID = "GetAllTransactionsByClientId";
export const TRANSACTION = "Transaction";
export const CONFIGURATION = "Configuration";
export const GET_ALL_CONFIGURATIONS_BY_CLIENT_ID =
  "GetAllConfigurationsByClientId";
export const FORGOT_PASSWORD = "ForgotPassword";
export const GATEWAY_ACCOUNT = "GatewayAccount";
export const GATEWAY_CREDENTIAL_KEY = "GatewayCredentialKey";
export const GET_BY_GATEWAY_ID = "GetByGatewayId";
export const GET_CLIENT_CREDENTIALS = "GetClientCredentials";
export const GENERATE_NEW_CLIENT_CREDENTIALS = "GenerateNewClientCredentials";
export const CLIENT_GATEWAY_MICROSERVICE = "ClientGatewayMicroservices";
export const CURRENCY_PAIR = "CurrencyPair";
export const RESPONSE_CODE = "ResponseCode";
export const CLIENT_USER = "ClientUser";
export const BANK_DESTINATION = "BankDestination";
export const GATEWAY_RESPONSE_CODE_MAP = "GatewayResponseCodeMap";
export const CURRENCY = "Currency";
export const GET_USER_INFO = "GetUserInfo";
export const GET_USER_CLIENTS = "GetUserClients";
export const GATEWAY_ACCOUNT_CURRENCY_PAIR = "GatewayAccountCurrencyPair";
export const TOGGLE_IS_ENABLED = "ToggleIsEnabled";
export const ACTIVATE = "activate";
export const CHANGE_PASSWORD = "ChangePassword";
export const TransactionMonitor = "TransactionMonitor";
export const GetTransactionLogDetails = "GetTransactionLogDetails";
export const UploadLogo = "UploadLogo";
export const SETTLEMENT_PROFILE = "SettlementProfile";
export const GET_GATEWAY_CHARGE_BEARER = "getgatewaychargebearer";
export const GET_CHARGE_TYPE = "getchargetype";
export const GET_BY_SETTLEMENT_PROFILE = "getbysettlementprofile";
export const SPLIT_CONFIGURATION = "SplitConfiguration";
export const GET_SPLIT_CONFIG_BY_PROFILE =
  "getsplitconfigbyprofile";
export const BANK = "Bank";
export const COLLECTION = "Collection";
export const GET_ALLCOLLECTIONS_BY_CLIENT_ID = "gettransactionsbyclientId";
export const GET_COLLECTION_COLLECTIONREFERENCE =
  "GetCollectionByCollectionReference";
export const GET_TRANSACTION_CURENCIES="gettransactioncurrencies"
export const GET_TRANSACTION_COUNT = "gettransactioncount"
export const GET_TRANSACTIONS_PER_TIME = "gettransactionspertime"
export const GET_TRANSACTIONS_PER_SERVICE = "gettransactionsperservice"
export const GET_TRANSACTION_PER_CUSTOMER = "gettransactionspercustomer";
export const GET_TRANSACTIONS_PER_CONFIG = "gettransactionsperconfig"
export const GET_ALL_SETTLEMENT_BY_CLIENT_ID = "getallbyclientid"
// constants for context
export const Modules = {
  GATEWAY_ACCOUNT: "GATEWAY_ACCOUNT",
  PAYMENT_GATEWAY: "PaymentGateway",
  CONFIGURATION: "CONFIGURATION",
  RESPONSE_CODE: "RESPONSE_CODE",
  CURRENCY_PAIR: "CURRENCY_PAIR",
  MICRO_SERVICE: "Microservice",
  COUNTRY: "Country",
  CURRENCY: "Currency",
  CLIENT_GATEWAY_MICROSERVICE: "CLIENT_GATEWAY_MICROSERVICE",
  PAYMENT_SERVICE_GROUP: "PaymentServiceGroup",
  PAYMENT_SERVICE: "PaymentService",
  ROLE: "Role",
  USER: "User",
  CLIENT: "CLIENT",
  CLIENT_TRANSACTIONS: "CLIENT_TRANSACTIONS",
  CLIENT_SERVICES: "CLIENT_SERVICES",
  GATEWAY_CREDENTIAL_KEY: "GATEWAY_CREDENTIAL_KEY",
  CLIENT_USER: "CLIENT_USER",
  DESTINATION_BANK: "DESTINATION_BANK",
  GATEWAY_RESPONSE_CODE_MAP: "GATEWAY_RESPONSE_CODE_MAP",
  SETTLEMENT_PROFILE: "SETTLEMENT_PROFILE",
  GATEWAY_ACCOUNT_CURRENCY_PAIR: "GATEWAY_ACCOUNT_CURRENCY_PAIR",
  SPLIT_CONFIGURATION: "SPLIT_CONFIGURATION",
};

export const microServiceCodes = ["ACCAGG", "DISAGG"];
export const LABELS = {
  SYNC_TEXT_PENDING: "Syncing Page",
  SYNC_TEXT_SUCCESS: "Page Updated",
};
export const MONTHS = ["January", "February", "March", "April", "May", "June", "July","August","September","October","November"]
export const DAYS = ['SUNDAY','MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY']
export const Colors = {
  main: "#261B64",
};

// This tabs have to follow this sequential order,
export const configurationTabRoutes = [
  "/home/configuration/info",
  "/home/configuration/gateway-account",
  "/home/configuration/bank-destination",
];
const DEMO = {
  BLANK_LINK: "#!",
};
export const BAR_COLOUR = "#3384B2";
export const BAR_HOVER_COLOUR = "#00659F";
export const TAB_INDEX = {
  CLIENTS: -1,
  CLIENT_INFO: 0,
  CLIENT_USERS: 1,
  CLIENT_TRANSACTIONS: 2,
  CLIENT_CLIENTSERVICE: 3,
  CLIENT_GATEWAY_ACCOUNT: 4,
  CLIENT_CONFIGURATION: 5,
  GATEWAY_ACCOUNT_CURRENCY_PAIR: 6,
};

export const GATEWAY_TAB_INDEX = {
  GATEWAYS: -1,
  GATEWAY_INFO: 0,
  GATEWAY_CREDENTIAL_KEY: 1,
};

export const entries = [
  {
    label: "10 Entries",
    value: 10,
  },
  {
    label: "20 Entries",
    value: 20,
  },
  {
    label: "50 Entries",
    value: 50,
  },
  {
    label: "100 Entries",
    value: 100,
  },
];

export const durations = [
  {
    label: "Today",
    value: 10,
  },
  {
    label: "This Week",
    value: 20,
  },
  {
    label: "This Week",
    value: 50,
  },
  {
    label: "This Month",
    value: 100,
  },
  {
    label: "This Year",
    value: 100,
  },
  {
    label: "All Time",
    value: 100,
  },
  {
    label: "Custom Date",
    value: 100,
  },
];

export const highestPayingCustomers = [
  {
    label: "Customer Name",
    value: 56000,
  },
  {
    label: "Customer Name",
    value: 28990,
  },
  {
    label: "Customer Name",
    value: 50635,
  },
  {
    label: "Customer Name",
    value: 32100,
  },
  {
    label: "Customer Name",
    value: 17600,
  },
];

export const mostUsedPaymentMethod = [
  {
    label: "Payment Method",
    value: 75410,
  },
  {
    label: "Payment Method",
    value: 98720,
  },
  {
    label: "Payment Method",
    value: 56550,
  },
  {
    label: "Payment Method",
    value: 24100,
  },
  {
    label: "Payment Method",
    value: 10830,
  },
];

export const priorityEnum = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];
export const clientsFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const paymentGatewayFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
  { label: "Status", value: "status" },
];
export const paymentServiceFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];

export const paymentGroupFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const currencyPairFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const countryFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const currencyFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const responseCodeFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const microserviceFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const userListFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const roleListFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const transactionListFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const clientServicesListFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const clientuserListFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const RoleEnum = [
  { label: "Admin", value: 0 },
  { label: "Settlement", value: 1 },
  { label: "Operations", value: 2 },
  { label: "Customer Support", value: 3 },
  { label: "Developer", value: 4 },
];
export const paymentServicesDummyEnum = [
  { label: "Local Disbursement", value: 0 },
  { label: "Disbursement Service", value: 1 },
  { label: "Collections Service", value: 2 },
];
export const microServicesDummyEnum = [
  { label: "Local Disbursement", value: 0 },
  { label: "Disbursement Micro Service", value: 1 },
  { label: "Collections Micro Service", value: 2 },
];
export const ClientFilterEnum = [
  { label: "Id", value: "id" },
  { label: "Name", value: "code" },
  { label: "Code", value: "name" },
];

export const transactionsDummy = [
  {
    customerName: "VGG Admin",
    Amount: "niweo",
    paymentService: "woio",
    Status: "mwoi",
    Transaction: "wmio",
    transactionFee: "mmfpop",
  },
  {
    customerName: "VGG Admin",
    Amount: "niweo",
    paymentService: "woio",
    Status: "mwoi",
    Transaction: "wmio",
    transactionFee: "mmfpop",
  },
  {
    customerName: "VGG Admin",
    Amount: "niweo",
    paymentService: "woio",
    Status: "mwoi",
    Transaction: "wmio",
    transactionFee: "mmfpop",
  },
  {
    customerName: "VGG Admin",
    Amount: "niweo",
    paymentService: "woio",
    Status: "mwoi",
    Transaction: "wmio",
    transactionFee: "mmfpop",
  },
  {
    customerName: "VGG Admin",
    Amount: "niweo",
    paymentService: "woio",
    Status: "mwoi",
    Transaction: "wmio",
    transactionFee: "mmfpop",
  },
];

export const bankDestinationDummy = [
  {
    DBBankCode: "GTB",
    DBClientName: "GTBank",
    DBConfigurationTag: "ASP",
    DBGatewayCode: "GTBNK",
    DBServiceCode: "LJG",
    DBServiceName: "Disbursement",
  },
  {
    DBBankCode: "GTB",
    DBClientName: "GTBank",
    DBConfigurationTag: "ASP",
    DBGatewayCode: "GTBNK",
    DBServiceCode: "LJG",
    DBServiceName: "Disbursement",
  },
  {
    DBBankCode: "GTB",
    DBClientName: "GTBank",
    DBConfigurationTag: "ASP",
    DBGatewayCode: "GTBNK",
    DBServiceCode: "LJG",
    DBServiceName: "Disbursement",
  },
  {
    DBBankCode: "GTB",
    DBClientName: "GTBank",
    DBConfigurationTag: "ASP",
    DBGatewayCode: "GTBNK",
    DBServiceCode: "LJG",
    DBServiceName: "Disbursement",
  },
];

export const configurationDummy = [
  {
    CClientName: "hjehhj",
    CServiceCode: "nsgjhhs",
    CServiceName: "bvgfh",
    CisDefault: "yes",
    CconfigurationTag: "hyqwuy",
    CuseDestinationBank: "no",
  },
  {
    CClientName: "hjehhj",
    CServiceCode: "nsgjhhs",
    CServiceName: "bvgfh",
    CisDefault: "yes",
    CconfigurationTag: "hyqwuy",
    CuseDestinationBank: "no",
  },
  {
    CClientName: "hjehhj",
    CServiceCode: "nsgjhhs",
    CServiceName: "bvgfh",
    CisDefault: "yes",
    CconfigurationTag: "hyqwuy",
    CuseDestinationBank: "no",
  },
  {
    CClientName: "hjehhj",
    CServiceCode: "nsgjhhs",
    CServiceName: "bvgfh",
    CisDefault: "yes",
    CconfigurationTag: "hyqwuy",
    CuseDestinationBank: "no",
  },
];

export const gatewayAccountDummy = [
  {
    GatewayName: "hghags",
    GatewayAccountName: "hggyk",
    GatewayAccountNumber: "gvtyt",
    GatewayAccountConfigurationTag: "bcvds",
    GatewayAccountisActive: "yes",
  },
  {
    GatewayName: "hghags",
    GatewayAccountName: "hggyk",
    GatewayAccountNumber: "gvtyt",
    GatewayAccountConfigurationTag: "bcvds",
    GatewayAccountisActive: "yes",
  },
  {
    GatewayName: "hghags",
    GatewayAccountName: "hggyk",
    GatewayAccountNumber: "gvtyt",
    GatewayAccountConfigurationTag: "bcvds",
    GatewayAccountisActive: "yes",
  },
  {
    GatewayName: "hghags",
    GatewayAccountName: "hggyk",
    GatewayAccountNumber: "gvtyt",
    GatewayAccountConfigurationTag: "bcvds",
    GatewayAccountisActive: "yes",
  },
];

export const gatewayAccountCurrencyPairDummy = [
  {
    GACgatewayAccountInfo: "njhgh",
    GACcurrencyPairCurrency: "drertg",
    GACcurrencyPairCountry: "cdfsxs",
  },
  {
    GACgatewayAccountInfo: "njhgh",
    GACcurrencyPairCurrency: "drertg",
    GACcurrencyPairCountry: "cdfsxs",
  },
  {
    GACgatewayAccountInfo: "njhgh",
    GACcurrencyPairCurrency: "drertg",
    GACcurrencyPairCountry: "cdfsxs",
  },
  {
    GACgatewayAccountInfo: "njhgh",
    GACcurrencyPairCurrency: "drertg",
    GACcurrencyPairCountry: "cdfsxs",
  },
];

export const clientPaymentServiceDummy = [
  {
    clientPaymentServiceName: "Local Disbursement",
    clientPaymentServiceCode: "09873655",
    clientPaymentServiceGroup: "hhh",
    clientPaymentServiceStatus: "active",
  },
  {
    clientPaymentServiceName: "International Disbursement",
    clientPaymentServiceCode: "08374656",
    clientPaymentServiceGroup: "hhh",
    clientPaymentServiceStatus: "active",
  },
  {
    clientPaymentServiceName: "Collections Service",
    clientPaymentServiceCode: "09873655",
    clientPaymentServiceGroup: "hhh",
    clientPaymentServiceStatus: "inactive",
  },
];
export const clientUsersDummy = [
  {
    clientUserEmail: "precious.uchendu@venturegardengroup.com",
    clientUserStatus: "active",
    clientUserFullName: "Precious Uchendu",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "samuel.awonorin@venturegardengroup.com",
    clientUserStatus: "inactive",
    clientUserFullName: "Samuel Awonorin",
    clientUserRole: "Admin",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "florence.adikwu@venturegardengroup.com",
    clientUserStatus: "active",
    clientUserFullName: "Florence Adikwu",
    clientUserRole: "Customer",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "michael.fabiyi@venturegardengroup.com",
    clientUserStatus: "inactive",
    clientUserFullName: "Michael Fabiyi",
    clientUserRole: "Admin",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "teslimi.yusuf@venturegardengroup.com",
    clientUserStatus: "active",
    clientUserFullName: "Tesilimi Yusuf",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "akinwale.agbaje@venturegardengroup.com",
    clientUserStatus: "inactive",
    clientUserFullName: "Akinwale Agbaje",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "emeka.ugochukwu@venturegardengroup.com",
    clientUserStatus: "active",
    clientUserFullName: "Emeka Ugochkwu",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "ayoola.kelani@venturegardengroup.com",
    clientUserStatus: "inactive",
    clientUserFullName: "Ayoola Kelani",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "olutoye.owojaye@venturegardengroup.com",
    clientUserStatus: "active",
    clientUserFullName: "Olutoye Owojaye",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "rachael.olujuyigbe@venturegardengroup.com",
    clientUserStatus: "inactive",
    clientUserFullName: "Rachael Olujuyigbe",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "bunmi.akinyemiju@venturegardengroup.com",
    clientUserStatus: "active",
    clientUserFullName: "Bunmi Akinyemiju",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
];

export const clientsDummy = [
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
];
export default DEMO;
