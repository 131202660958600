import React from "react";
import "./error.css";
export const ErrorPage = ({ unAuthorized, history, showRefresh }) => {
  return (
    <>
      <div id="error">
        <div className="error">
          <div className="error-404">
            <h1>:(</h1>
          </div>
          <h2>Something Happened</h2>
          <p>There was a glitch, We tried all we could, Kindly hit Refresh again.</p>
          {unAuthorized === null && (
            <a href="#!" onClick={() => window.location.reload()}>
              Refresh
            </a>
          )}
          &nbsp;
          {showRefresh === true && (
            <a href="#!" onClick={() => window.location.reload()}>
              Refresh
            </a>
          )}
          &nbsp;
          <a href="#!" onClick={() => history.push("/")}>
            Home
          </a>
        </div>
      </div>
    </>
  );
};
