export const gatewayAccountCurrencyPairCreateErrors = {
  GACclientId: "",
  GACgatewayAccountId: "",
  GACcurrencyPairId: "",
  GACisEnabledCreate: false,
  all: new Set(),
};

export const configurationCreateErrors = {
  CconfigurationTag: "",
  Cdescription: "",
  all: new Set(),
};

export const gatewayAccountCreateErrors = {
  GatewayAccountGatewayId: "",
  GatewayAccountNumber: "",
  GatewayAccountName: "",
  GatewayAccountConfigurationId: "",
  GatewayAccountServiceId:"",
  GatewayAccountPriority: "",
  GatewayAccountUseAutoSwitch: false,
  GatewayAccountCredentials: "",
  all: new Set(),
};

export const SplitConfigurationCreateErrors = {
  SCsettlementProfileId: "",
  SCbankId: "",
  SCaccountName: "",
  SCaccountNumber: "",
  SCpercentageValue: "",
  SCflatValue: "",
  SCpercentageCap: "",
  SCchargeType: "",
  all: new Set(),
};
export const SplitConfigurationEditErrors = {
  SCsettlementProfileId: "",
  SCbankId: "",
  SCaccountName: "",
  SCaccountNumber: "",
  SCpercentageValue: "",
  SCflatValue: "",
  SCpercentageCap: "",
  SCchargeType: "",
  all: new Set(),
};

export const bankDestinationCreateErrors = {
  DBBankCode: "",
  DBGatewayId: "",
  DBConfigurationId: "",
  DBserviceId:"",
  IsEnabled: false,
  all: new Set(),
};
export const settlementProfileCreateErrors = {
  SPdescription: "",
  SPgatewayChargeBearer: "",
  SPsettlementTag: "",
  SPserviceId: "",
  SPconfigurationId:"",
  all: new Set(),
};

export const settlementProfileEditErrors = {
  SPdescription: "",
  SPgatewayChargeBearer: "",
  SPsettlementTag: "",
  SPserviceId: "",
  SPconfigurationId:"",
  all: new Set(),
};
export const clientServiceCreateErrors = {
  CSserviceId: "",
  all: new Set(),
};
export const paymentGatewayCreateErrors = {
  microService: "",
  GatewayCode: "",
  GatewayName: "",
  GatewayEnabledCreate: false,
  GatewayDescription: "",
  GatewayCountry: "",
  GatewayIsLocal: false,
  all: new Set(),
};
export const destinationBankCreateErrors = {
  DBBankCode: "",
  DBGatewayId: "",
  DBConfigurationId: "",
  all: new Set(),
};
export const clientsClientServiceCreateErrors = {
  CCserviceId: "",
  CCisEnabled: false,
  all: new Set(),
};
export const clientsCreateErrors = {
  ClientName: "",
  ClientCode: "",
  ClientuseAML: false,
  ClientisEnabled: false,
  ClientcallbackUrl: "",
  all: new Set(),
};
export const currencyPairCreateErrors = {
  SendingCurrencyCode: "",
  SendingCountryCode: "",
  ReceivingCurrencyCode: "",
  ReceivingCountryCode: "",
  all: new Set(),
};

export const gatewayCredentialKeysCreateErrors = {
  GCKKey: "",
  all: new Set(),
};

export const responseCodeCreateErrors = {
  ResponseCode: "",
  ResponseMessage: "",
  ServiceId: "",
  all: new Set(),
};

export const countryCreateErrors = {
  COName: "",
  COISO2: "",
  COISO3: "",
  CONumericCode: "",
  all: new Set(),
};

export const currencyCreateErrors = {
  CName: "",
  CCurrencyCode: "",
  CNumericCode: "",
  all: new Set(),
};

export const paymentServiceCreateErrors = {
  paymentServiceName: "",
  paymentServiceCode: "",
  microService: "",
  paymentServiceGroup: "",
  paymentservicedescription: "",
  ServiceEnabledCreate: false,
  UseAMLCreate: false,
  all: new Set(),
};
export const clientGatewayMicroserviceCreateErrors = {
  CGClient: "",
  CGMicroService: "",
  CGGateway: "",
  CGEnabledCreate: false,
  all: new Set(),
};

export const gatewayResponseCodeMapCreateErrors = {
  GRCGatewayResponseMessage: "",
  GRCGatewayResponseCode: "",
  GRCResponseCodeId: "",
  GRCGatewayId: "",
  all: new Set(),
};
export const microServiceCreateErrors = {
  MicroServiceName: "",
  MicroServiceCode: "",
  MicroServiceDescription: "",
  MicroServiceEnabled: false,
  all: new Set(),
};
export const userCreateErrors = {
  FirstName: "",
  LastName: "",
  Email: "",
  RoleName: "",
  Phone:"",
  all: new Set(),
};
export const roleCreatErrors = {
  RRoleName: "",
  RDescription: "",
  all: new Set(),
};
export const paymentServiceGroupCreateErrors = {
  GroupName: "",
  GroupDescription: "",
  all: new Set(),
};

export const changePasswordErrors = {
  CCurrentPassword: "",
  password: "",
  confirmPassword:"",
  all: new Set(),
};

export const forgotpasswordErrors = {
  Email: "",
  all: new Set(),
};
export const loginErrors = {
  Email: "",
  Password: "",
  all: new Set(),
};

export const resetPasswordErrors = {
  password: "",
  confirmPassword: "",
  all: new Set(),
};

export const setupAccountErrors = {
  ClientName: "",
  ClientCode: "",
  ClientuseAML: false,
  ClientCountry: "",
  ClientFirstName:"",
  ClientLastName:"",
  ClientEmail:"",
  ClientPhone:"",
  ClientRefCode:"",
  all: new Set(),
};

export const oldsetupAccountErrors = {
  rpassword: "",
  rconfirmPassword: "",
  all: new Set(),
};

//

export const gatewayAccountEditErrors = {
  GatewayAccountGatewayId: "",
  GatewayAccountNumber: "",
  GatewayAccountName: "",
  GatewayAccountConfigurationId: "",
  GatewayAccountPriority: "",
  GatewayAccountServiceId:"",
  all: new Set(),
};

export const clientsEditErrors = {
  ClientName: "",
  ClientCode: "",
  ClientcallbackUrl: "",
  all: new Set(),
};

export const gatewayCredentialKeysEditErrors = {
  GCKKey: "",
  all: new Set(),
};
export const configurationEditErrors = {
  CconfigurationTag: "",
   Cdescription: "",
  all: new Set(),
};
export const currencyPairEditErrors = {
  SendingCurrencyCode: "",
  SendingCountryCode: "",
  ReceivingCurrencyCode: "",
  ReceivingCountryCode: "",
  all: new Set(),
};
export const gatewayAccountCurrencyPairEditErrors = {
  GACclientId: "",
  GACgatewayAccountId: "",
  GACcurrencyPairId: "",
  all: new Set(),
};
export const countryEditErrors = {
  COName: "",
  COISO2: "",
  COISO3: "",
  CONumericCode: "",
  all: new Set(),
};
export const currencyEditErrors = {
  CName: "",
  CCurrencyCode: "",
  CNumericCode: "",
  all: new Set(),
};

export const clientsClientServiceEditErrors = {
  CCserviceId: "",
  CCisEnabled: false,
  all: new Set(),
};
export const gatewayResponseCodeMapEditErrors = {
  GRCGatewayResponseMessage: "",
  GRCGatewayResponseCode: "",
  GRCResponseCodeId: "",
  GRCGatewayId: "",
  all: new Set(),
};
export const destinationBankEditErrors = {
  DBBankCode: "",
  DBGatewayId: "",
  DBConfigurationId: "",
  DBserviceId:"",
  all: new Set(),
};

export const responseCodeEditErrors = {
  ResponseCode: "",
  ResponseMessage: "",
  ServiceId: "",
  all: new Set(),
};
export const paymentServiceEditErrors = {
  paymentServiceName: "",
  paymentServiceCode: "",
  microService: "",
  paymentServiceGroup: "",
  paymentservicedescription: "",
  all: new Set(),
};

export const paymentServiceGroupEditErrors = {
  GroupName: "",
  GroupDescription: "",
  all: new Set(),
};

export const paymentGatewayEditErrors = {
  microService: "",
  GatewayCode: "",
  GatewayName: "",
  GatewayDescription: "",
  GatewayCountry: "",
  all: new Set(),
};
export const userEditErrors = {
  FirstName: "",
  LastName: "",
  Email: "",
  RoleName: "",
  Phone:"",
  all: new Set(),
};
export const clientGatewayMicroserviceEditErrors = {
  CGClient: "",
  CGMicroService: "",
  CGGateway: "",
  all: new Set(),
};

export const microServiceEditErrors = {
  MicroServiceName: "",
  MicroServiceCode: "",
  MicroServiceDescription: "",
  all: new Set(),
};

export const transactionsCreateErrors = {
  CTserviceCode: "",
  CTconfigurationTag: "",
  CTfirstName: "",
  CTlastName: "",
  CTemail: "",
  CTaddress: "",
  CTcity: "",
  CTstate: "",
  CTphoneNumber: "",
  CTcountryCode: "",
  CTcurrencyCode: "",
  CTidentificationType: "",
  CTidentificationNumber: "",
  CTidentificationIssuedDate: "",
  CTidentificationExpiryDate: "",
  CTsourceAmount: "",
  CTdebitAccountName: "",
  CTdebitAccountNumber: "",
  CTdebitAccountBankCode: "",
  CTewalletId: "",
  CTBfirstName: "",
  CTBlastName: "",
  CTBemail: "",
  CTBaddress: "",
  CTBcity: "",
  CTBstate: "",
  CTBphoneNumber: "",
  CTBcountryCode: "",
  CTBcurrencyCode: "",
  CTBbeneficiaryAmount: "",

  CTbankName: "",
  CTbankCode: "",
  CTaccountNumber: "",
  CTaccountName: "",
  CTbankAddress: "",
  CTbankCity: "",
  CTbankCountry: "",
  CTbankBranch: "",

  CTiban: "",
  CTswiftBICCode: "",
  CTcnapsCode: "",
  CTpayerId: "",
  CTtransactionReference: "",
  CTnarration: "",
  all: new Set(),
};

export const microserviceFilterErrors = {
  MPaymentGateway: "",
  MPaymentService: "",
  MStatus: "",
};
