import actionTypes from "../actions/actionTypes";

const initialState = {
  clientservices: [],
};

const clientServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CLIENTSERVICES_SUCCESS:
      return {
        ...state,
        clientservices: action.payload.clientservices,
      };

    default:
      return state;
  }
};

export default clientServicesReducer;
