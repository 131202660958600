import React from "react";
import "./customtooltip.scss";
export default function CustomToolTip({ show, content, tooltiptext }) {
  return (
    <>
      {show && (
        <div className="ctooltip">
          {content}
          <span className="tooltiptext">{tooltiptext}</span>
        </div>
      )}
    </>
  );
}
