import actionTypes from "../actions/actionTypes";

const initialState = {
  gatewaychargebearer: [],
};

const gatewaychargebearerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GATEWAY_CHARGE_BEARER_SUCCESS:
      return {
        ...state,
        gatewaychargebearer: action.payload.gatewaychargebearer,
      };

    default:
      return state;
  }
};

export default gatewaychargebearerReducer;
