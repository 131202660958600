import actionTypes from "../actions/actionTypes";

const initialState = {
  clientConfigurations: [],
  currentConfiguration: {},
};

const clientConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CLIENT_CONFIGURATIONS:
      return {
        ...state,
        clientConfigurations: action.payload.clientConfigurations,
      };

    case actionTypes.SAVE_CURRENT_CONFIGURATION_SUCCESS:
      return {
        ...state,
        currentConfiguration: action.payload.currentConfiguration,
      };

    default:
      return state;
  }
};

export default clientConfigurationReducer;
