import { appHelpers } from "appHelpers";

export const ExportService = (baseUrl, Axios, token, history, clientId) => {
  const instance = Axios.create({
    baseURL: baseUrl,
    headers: { Authorization: token, ProfileObject: clientId },
    // you can add other headers here which has been passed from AuthorizedLayout as arguments
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      let status = error.response.status;
      if (status === 403) {
        appHelpers.alertError("Requested Resource is Forbidden ");
      } else if (status === 401) {
        appHelpers.alertError("UnAuthorized or Access Resource");
        history.push("/");
      }
    }
  );

  const getApiUrl = (controller, action) => {
    return `${controller}/${action}/`;
  };

  const getRestUrl = (controller, action) => {
    return `${controller}/${action}`;
  };

  const getAllItems = (controller, action, data) => {
    return instance.post(getApiUrl(controller, action), data);
  };

  const exportListItems = (
    controller,
    action,
    pageSize = 10000,
    pageNumber = 1,
    searchData
  ) => {
    let url = `?pageSize=${pageSize}&pageNumber=${pageNumber}`;

    if (
      searchData &&
      searchData.keyword !== null &&
      searchData.keyword !== "" &&
      searchData.keyword !== undefined
    ) {
      url += `&keyword=${searchData.keyword}`;
    }
    if (
      searchData &&
      searchData.status !== null &&
      searchData.status !== "" &&
      searchData.status !== undefined
    ) {
      url += `&status=${searchData.status}`;
    }
    if (
      searchData &&
      searchData.clientId !== null &&
      searchData.clientId !== "" &&
      searchData.clientId !== undefined
    ) {
      url += `&clientId=${searchData.clientId}`;
    }
    if (
      searchData &&
      searchData.gatewayId !== null &&
      searchData.gatewayId !== "" &&
      searchData.gatewayId !== undefined
    ) {
      url += `&gatewayId=${searchData.gatewayId}`;
    }
    if (
      searchData &&
      searchData.microserviceId !== null &&
      searchData.microserviceId !== "" &&
      searchData.microserviceId !== undefined
    ) {
      url += `&microserviceId=${searchData.microserviceId}`;
    }
    if (
      searchData &&
      searchData.useAML !== null &&
      searchData.useAML !== "" &&
      searchData.useAML !== undefined
    ) {
      url += `&useAML=${searchData.useAML}`;
    }
    if (
      searchData &&
      searchData.isAdmin !== null &&
      searchData.isAdmin !== "" &&
      searchData.isAdmin !== undefined
    ) {
      url += `&isAdmin=${searchData.isAdmin}`;
    }
    if (
      searchData &&
      searchData.gatewayAccountId !== null &&
      searchData.gatewayAccountId !== "" &&
      searchData.gatewayAccountId !== undefined
    ) {
      url += `&gatewayAccountId=${searchData.gatewayAccountId}`;
    }
    if (
      searchData &&
      searchData.currencyPairId !== null &&
      searchData.currencyPairId !== "" &&
      searchData.currencyPairId !== undefined
    ) {
      url += `&currencyPairId=${searchData.currencyPairId}`;
    }
    if (
      searchData &&
      searchData.serviceGroupId !== null &&
      searchData.serviceGroupId !== "" &&
      searchData.serviceGroupId !== undefined
    ) {
      url += `&serviceGroupId=${searchData.serviceGroupId}`;
    }
    if (
      searchData &&
      searchData.CanUseDestinationBank !== null &&
      searchData.CanUseDestinationBank !== "" &&
      searchData.CanUseDestinationBank !== undefined
    ) {
      url += `&CanUseDestinationBank=${searchData.CanUseDestinationBank}`;
    }
    if (
      searchData &&
      searchData.roleId !== null &&
      searchData.roleId !== "" &&
      searchData.roleId !== undefined
    ) {
      url += `&roleId=${searchData.roleId}`;
    }
    return instance.get(`${getRestUrl(controller, action)}${url}`);
  };
  const exportListItemsById = (
    controller,
    action,
    pageSize = 1000,
    pageNumber = 1,
    searchData
  ) => {
    let url = `${
      searchData.Id && `/${searchData.Id}`
    }?pageSize=${pageSize}&pageNumber=${pageNumber}`;

    if (
      searchData &&
      searchData.keyword !== null &&
      searchData.keyword !== "" &&
      searchData.keyword !== undefined
    ) {
      url += `&keyword=${searchData.keyword}`;
    }
    if (
      searchData &&
      searchData.status !== null &&
      searchData.status !== "" &&
      searchData.status !== undefined
    ) {
      url += `&status=${searchData.status}`;
    }
    if (
      searchData &&
      searchData.gatewayAccountId !== null &&
      searchData.gatewayAccountId !== "" &&
      searchData.gatewayAccountId !== undefined
    ) {
      url += `&gatewayAccountId=${searchData.gatewayAccountId}`;
    }
    if (
      searchData &&
      searchData.currencyPairId !== null &&
      searchData.currencyPairId !== "" &&
      searchData.currencyPairId !== undefined
    ) {
      url += `&currencyPairId=${searchData.currencyPairId}`;
    }
    if (
      searchData &&
      searchData.gatewayId !== null &&
      searchData.gatewayId !== "" &&
      searchData.gatewayId !== undefined
    ) {
      url += `&gatewayId=${searchData.gatewayId}`;
    }
    if (
      searchData &&
      searchData.configurationId !== null &&
      searchData.configurationId !== "" &&
      searchData.configurationId !== undefined
    ) {
      url += `&configurationId=${searchData.configurationId}`;
    }
    if (
      searchData &&
      searchData.useDestinationBank !== null &&
      searchData.useDestinationBank !== "" &&
      searchData.useDestinationBank !== undefined
    ) {
      url += `&useDestinationBank=${searchData.useDestinationBank}`;
    }
    if (
      searchData &&
      searchData.serviceId !== null &&
      searchData.serviceId !== "" &&
      searchData.serviceId !== undefined
    ) {
      url += `&serviceId=${searchData.serviceId}`;
    }
    if (
      searchData &&
      searchData.startDate !== null &&
      searchData.startDate !== "" &&
      searchData.startDate !== undefined
    ) {
      url += `&startDate=${searchData.startDate}`;
    }
    if (
      searchData &&
      searchData.endDate !== null &&
      searchData.endDate !== "" &&
      searchData.endDate !== undefined
    ) {
      url += `&endDate=${searchData.endDate}`;
    }
    return instance.get(`${getRestUrl(controller, action)}${url}`);
  };

  const getReduxItems = (
    controller,
    action,
    pageSize = 1000,
    pageNumber = 1
  ) => {
    return instance.get(
      `${getRestUrl(
        controller,
        action
      )}?pageSize=${pageSize}&pageNumber=${pageNumber}`
    );
  };

  const getReduxItemsById = (
    controller,
    action,
    pageSize = 1000,
    pageNumber = 1,
    id
  ) => {
    return instance.get(
      `${getRestUrl(
        controller,
        action
      )}/${id}?pageSize=${pageSize}&pageNumber=${pageNumber}`
    );
  };

  // all other api service functions can be created here which is then accessible to other routes and components in the Authorized Layout

  return {
    getReduxItemsById,
    exportListItemsById,
    exportListItems,
    getAllItems,
    getReduxItems,
  };
};
