import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "../../routes";
// import Header from '../Header/Header';
import Navigation from "../Navigation";
import NavBar from "../NavBar";
import Loader from "../Loader";
import { connect } from "react-redux";
import windowSize from "react-window-size";
import actionTypes from "../../logic/actions/actionTypes";
import Fullscreen from "react-full-screen";
import Aux from "../../components/hoc/_Aux";
import Breadcrumb from "../Breadcrumb";
import ScrollToTop from "components/ScrollToTop";

class Wrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevScrollpos: window.pageYOffset,
    };
  }

  componentDidMount() {}

  fullScreenExitHandler = () => {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      this.props.onFullScreenExit();
    }
  };

  UNSAFE_componentWillMount() {
    if (this.props.windowWidth > 992 && this.props.windowWidth <= 1024 && this.props.layout !== "horizontal") {
      this.props.onComponentWillMount();
    }
  }

  mobileOutClickHandler() {
    if (this.props.windowWidth < 992 && this.props.collapseMenu) {
      this.props.onComponentWillMount();
    }
  }

  render() {
    /* full screen exit call */
    document.addEventListener("fullscreenchange", this.fullScreenExitHandler);
    document.addEventListener("webkitfullscreenchange", this.fullScreenExitHandler);
    document.addEventListener("mozfullscreenchange", this.fullScreenExitHandler);
    document.addEventListener("MSFullscreenChange", this.fullScreenExitHandler);
    const menu = routes.map((route, index) => {
      return route.component ? <Route key={index} path={route.path} exact={route.exact} name={route.name} render={props => <route.component {...props} {...this.props} />} /> : null;
    });
    return (
      <Aux>
        <Fullscreen enabled={this.props.isFullScreen}>
          {/* <div>Hello Everything was Done here</div> */}
          {/* <Header /> */}
          <Navigation history={this.props.history} {...this.props} {...this.state} />
          <NavBar codes={this.props.codes} permissions={this.props.permissions} history={this.props.history} />
          <ScrollToTop>
            <div className="pcoded-main-container" onClick={() => this.mobileOutClickHandler}>
              <div className="pcoded-wrapper">
                <div className="pcoded-content">
                  <div className="pcoded-inner-content">
                    <Breadcrumb />
                    <div className="main-body">
                      <div className="page-wrapper">
                        <Suspense fallback={<Loader />}>
                          <Switch>
                            {menu}
                            <Redirect from="/" to={this.props.defaultPath} />
                          </Switch>
                        </Suspense>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={true}
              // onClose={handleClose}
              message="I love snacks"
              key={"top-center"}
              children={<SuccessNotification />}
            /> */}
          </ScrollToTop>
        </Fullscreen>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultPath: state.navigation.defaultPath,
    isFullScreen: state.navigation.isFullScreen,
    collapseMenu: state.navigation.collapseMenu,
    configBlock: state.navigation.configBlock,
    layout: state.navigation.layout,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFullScreenExit: () => dispatch({ type: actionTypes.FULL_SCREEN_EXIT }),
    onComponentWillMount: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(windowSize(Wrapper));
