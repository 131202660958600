import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";

import "../../../assets/scss/style.scss";
import Aux from "../../../components/hoc/_Aux";
import Breadcrumb from "../../../components/Breadcrumb";
import { loginErrors } from "validation";
import CustomInput from "components/Input/CustomInput";
import { CircularProgress } from "@material-ui/core";
import { appHelpers } from "appHelpers";
import { useDispatch } from "react-redux";
import {
  saveUserData,
  saveMyClientsData,
  savemyClientId,
  saveCurrentClient,
  saveExpiration,
} from "logic/actions/actionTypes";

const LOCATIONSTORAGEKEY = "clientconfigurationNavigationKey";

const SignIn = (props) => {
  const dispatch = useDispatch();
  const hasError = useRef(null);
  const [data, setData] = useState({
    Email: "",
    Password: "",
  });
  const [err, setError] = useState(loginErrors);
  const [hidden, setHidden] = useState(true);
  const [isError, setisError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableLogin, setdisableLogin] = useState(false);
  const [errorText, seterrorText] = useState("");

  const toggleShow = () => {
    setHidden(!hidden);
  };

  const login = () => {
    //
    setdisableLogin(true);
    setLoading(true);
    seterrorText("");
    const { Constants } = props;

    props.validatorAll(
      [
        { name: "Email", value: data.Email, label: "Email" },
        { name: "Password", value: data.Password, label: "Password" },
      ],
      "Login",
      setError,
      err,
      hasError
    );
    if (!hasError.current) {
      setLoading(false);
      seterrorText("");
      setdisableLogin(false);
      return;
    }
    if (Object.keys(hasError.current).length > 0) {
      setdisableLogin(false);
      setLoading(false);
      return;
    }
    if (err.Email && err.Password === "") {
      setLoading(false);
    }

    const payload = {
      userName: data.Email,
      password: data.Password,
    };
    // props.history.push("/home/welcome");
    const LoginService = props.Service(null, null); 
    LoginService.createItem(payload, Constants.ACCOUNT, Constants.LOGIN)
      .then((res) => {
        const { data } = res;
        if (data.requestSuccessful === true) {
          setdisableLogin(false);
          setLoading(false);
          const { responseData } = data;
          const cipherText = appHelpers
            .cryptographyService()
            .encrypt(responseData.accessToken);
          const permissions = appHelpers
            .cryptographyService()
            .encryptObject(responseData.permissions);
          const expiresIn = appHelpers
            .cryptographyService()
            .encryptObject(responseData.expiresIn);
          dispatch(saveExpiration(responseData.expiresIn));
          console.log({responseData})
          dispatch(saveUserData(responseData));
          // console.log("my clients", responseData)
          // picking the first item in the clients list by default
          if (responseData.clients !== null) {
            let firstClient = responseData.clients[0];
            dispatch(saveCurrentClient(firstClient));
            // debugger;
            let firstClientId = firstClient.id;
            dispatch(savemyClientId(firstClientId));
            dispatch(saveMyClientsData(responseData.clients));
            localStorage.setItem(
              "SERVICEMANAGER.CLIENT_UI_",
              JSON.stringify(cipherText)
            );
          console.log({responseData, permissions})

            localStorage.setItem(
              "SERVICEMANAGER.CLIENT_IU_",
              JSON.stringify(permissions)
            );
            localStorage.setItem(
              "SERVICEMANAGER.UI--_",
              JSON.stringify(expiresIn)
            );
            localStorage.setItem(LOCATIONSTORAGEKEY, -1);
            props.history.push("/home/dashboard");
          } else {
            setisError(true);
            seterrorText("An Error Occurred, Please Contact an Admin.");
            return;
          }
        } else {
          setdisableLogin(false);
          setLoading(false);
          setisError(true);
          seterrorText(data.message);
        }
      })
      .catch((err) => {
        console.log({err})
        if (err && err.response === undefined) {
          setdisableLogin(false);
          setLoading(false);
          setisError(true);
          seterrorText("Check Your Network and try again.");
        }
        if (err.response) {
          setdisableLogin(false);
          setLoading(false);
          setisError(true);
        }
      });
  };
  const handleCreateFormInputChange =
    (input, label) =>
    ({ target: { value } }) => {
      setData((prevState) => ({
        ...prevState,
        [input]: value,
      }));
      props.validator(
        { name: input, value: value, label: label },
        "Login",
        setError,
        err
      );
    };
  const enterHandler = () => {
    window.addEventListener("keyup", function (event) {
      // 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        event.preventDefault();
        // Trigger the button element with a click
        let loginTrigger = document.querySelector("#loginButton");
        if (loginTrigger && loginTrigger !== null) {
          loginTrigger.click();
        }
      }
    });
  };
  enterHandler();
  return (
    <Aux>
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="card">
            <div className="card-body text-center">
              <img
                src={"/vigipay_black.svg"}
                alt="Logo"
                style={{ width: "190px", height: "80px" }}
              />

              {/* <h3 className="mb-2">
                <b>Client Portal Login</b>
              </h3> */}
              <h6 className="text-muted mt-3" style={{ marginBottom: "2rem" }}>
                Enter login details to proceed
              </h6>
              <div className="input-group ">
                <CustomInput
                  errorText={err.Email}
                  showError={err.Email.length > 0}
                  value={data.Email}
                  onChange={handleCreateFormInputChange("Email", "Email")}
                  id="login-email"
                  labelText="Email Address"
                  placeholder="enter an email"
                />
              </div>

              <CustomInput
                errorText={err.Password}
                showError={err.Password.length > 0}
                value={data.Password}
                onChange={handleCreateFormInputChange("Password", "Password")}
                id="login-password"
                labelText="Password"
                placeholder="enter a password"
                type={hidden ? "password" : "text"}
                endIcon={
                  <>
                    {hidden ? (
                      <i
                        onClick={toggleShow}
                        className="fa fa-eye"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        onClick={toggleShow}
                        className="fa fa-eye-slash"
                        aria-hidden="true"
                      ></i>
                    )}
                  </>
                }
              />
              {isError && (
                <h6 className="text-danger " style={{ marginBottom: "2rem" }}>
                  {errorText}
                </h6>
              )}

              <p className="mb-2 text-muted" style={{ textAlign: "end" }}>
                {" "}
                <NavLink to="/forgot-password" style={{ color: "#2992D0" }}>
                  Forgot Password ?
                </NavLink>
              </p>
              <button
                className="btn  shadow-2 mb-4 "
                style={{
                  width: "100%",
                  backgroundColor: "#2992D0",
                  color: "#fff",
                }}
                onClick={login}
                id="loginButton"
                disabled={disableLogin}
              >
                {loading && (
                  <CircularProgress
                    size={20}
                    style={{ marginBottom: "-4px" }}
                    color="white"
                  />
                )}{" "}
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </Aux>
  );
};

export default SignIn;
