import actionTypes from "../actions/actionTypes";

const initialState = {
  settlementProfile: [],
};

const settlementProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SETTLEMENT_PROFILE_SUCCESS:
      return {
        ...state,
        settlementProfile: action.payload.settlementProfile,
      };

    default:
      return state;
  }
};

export default settlementProfileReducer;
